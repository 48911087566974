import { useId } from 'react';
import styles from './CheckboxGroup.module.css';

export const CheckboxGroup = ({ labelValue, checked, onChange }) => {
  const inputId = useId();
  return (
    <div className={styles.checkboxGroup}>
      <input
        id={`same-as-shipping${inputId}`}
        type="checkbox"
        checked={checked}
        onChange={onChange}
      ></input>
      <label
        className={styles.checkboxSwitchLable}
        htmlFor={`same-as-shipping${inputId}`}
      ></label>
      <label
        className={styles.checkboxTextLable}
        htmlFor={`same-as-shipping${inputId}`}
      >
        {labelValue}
      </label>
    </div>
  );
};
