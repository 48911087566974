import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  createInvoiceAPI,
  getPaymentMethodsAPI,
} from '../../repositories/CheckoutRepository';
import { REDUX_ASYNC_FETCH_STATUS } from '../../constants';

const initialState = {
  invoice: null,
  invoiceCreatingStatus: REDUX_ASYNC_FETCH_STATUS.idle,
  invoiceError: null,
  paymentMethods: [],
  paymentMethodStatus: REDUX_ASYNC_FETCH_STATUS.idle,
  paymentMethodsError: null,
};

export const getPaymentMethods = createAsyncThunk(
  'checkout/getPaymentMethods',
  async (storeId) => {
    const result = await getPaymentMethodsAPI(storeId);
    return result;
  },
);

export const createInvoice = createAsyncThunk(
  'checkout/createInvoice',
  async ({
    customerId,
    subTotal,
    finalTotal,
    currency,
    storeId,
    paymentMethodId,
    promocodeId,
  }) => {
    const result = await createInvoiceAPI(
      customerId,
      subTotal,
      finalTotal,
      currency,
      storeId,
      paymentMethodId,
      promocodeId,
    );
    return result;
  },
);

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPaymentMethods.pending, (state) => {
        state.paymentMethodStatus = REDUX_ASYNC_FETCH_STATUS.loading;
      })
      .addCase(getPaymentMethods.fulfilled, (state, action) => {
        state.paymentMethodStatus = REDUX_ASYNC_FETCH_STATUS.succeeded;
        state.paymentMethods = action.payload;
      })
      .addCase(getPaymentMethods.rejected, (state) => {
        state.paymentMethodStatus = REDUX_ASYNC_FETCH_STATUS.falied;
      })
      .addCase(createInvoice.pending, (state) => {
        state.invoiceCreatingStatus = REDUX_ASYNC_FETCH_STATUS.loading;
      })
      .addCase(createInvoice.fulfilled, (state, action) => {
        state.invoiceCreatingStatus = REDUX_ASYNC_FETCH_STATUS.succeeded;
        state.invoice = action.payload;
      })
      .addCase(createInvoice.rejected, (state, action) => {
        state.invoiceCreatingStatus = REDUX_ASYNC_FETCH_STATUS.falied;
        state.error = action.payload;
      });
  },
});

export default checkoutSlice.reducer;
