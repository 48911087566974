import { DEFAULT_GATEWAY_HOST } from '../../../constants';
export function apiStoresPath() {
  return `${DEFAULT_GATEWAY_HOST}/stores/`;
}

export function apiStorePath() {
  return `${DEFAULT_GATEWAY_HOST}/store/`;
}

export function apiStoreByIdPath(storeId) {
  return `${apiStorePath()}${!storeId ? '0' : storeId}`;
}

export function apiStoreCreatePath() {
  return `${apiStorePath()}create`;
}

export function apiStoreUpdatePath(storeId) {
  return `${apiStoreByIdPath(storeId)}/update`;
}

export function apiStoreUpdateSettingsPath(storeId) {
  return `${apiStoreByIdPath(storeId)}/settings/update`;
}

export function apiStoreDeletePath(storeId) {
  return `${apiStoreByIdPath(storeId)}/delete`;
}

export function apiPaymentMethodsPath() {
  return `${DEFAULT_GATEWAY_HOST}/payment_methods`;
}

export function apiPaymentMethodByStorePath(storeId, paymentMethodId) {
  return `${apiStoresPath()}${
    !storeId || storeId.length === 0 ? '0' : storeId
  }${apiPaymentMethodsPath()}/${
    !paymentMethodId || paymentMethodId.length === 0 ? '0' : paymentMethodId
  }`;
}

export function apiPaymentMethodCreateByStorePath(storeId) {
  return `${apiStoresPath()}${
    !storeId || storeId.length === 0 ? '0' : storeId
  }${apiPaymentMethodsPath()}/create`;
}

export function apiPaymentMethodUpdateByStorePath(storeId, paymentMethodId) {
  return `${apiPaymentMethodByStorePath(storeId, paymentMethodId)}/update`;
}

export function apiPaymentMethodDeleteByStorePath(storeId, paymentMethodId) {
  return `${apiPaymentMethodByStorePath(storeId, paymentMethodId)}/delete`;
}

const apiStoresRoutes = {
  apiStoresPath,
  apiStorePath,
  apiStoreByIdPath,
  apiStoreCreatePath,
  apiStoreUpdatePath,
  apiStoreUpdateSettingsPath,
  apiStoreDeletePath,
  apiPaymentMethodsPath,
  apiPaymentMethodByStorePath,
  apiPaymentMethodCreateByStorePath,
  apiPaymentMethodUpdateByStorePath,
  apiPaymentMethodDeleteByStorePath,
};

export default apiStoresRoutes;
