import React, { useEffect, memo } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import Detail from './pages/Detaiil/Detail';
import Category from './pages/Category';
import Products from './pages/Product/Products';

import Cart from './pages/Cart';
import Saved from './pages/Saved';
import Checkout from './pages/Checkout/Checkout';
import CashApp from './components/CashApp/CashApp';
import Auth from './components/Auth/Auth';

import { REDUX_ASYNC_FETCH_STATUS } from './constants';
import { fetchCategories } from './redux/slices/categorySlice';
import { setCurrentStore } from './redux/slices/storesSlice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts } from './redux/slices/productsSlice';
import {
  setCurrentCustomer,
  getCurrentUserId,
} from './redux/slices/customersSlice';
import { fetchCartItems } from './redux/slices/cartSlice';

const tele = window.Telegram.WebApp;
export default memo(function App() {
  const dispatch = useDispatch();
  const locationPage = useLocation();
  const customerId = useSelector(getCurrentUserId);

  const {
    stores,
    status: storesStatus,
    currentStoreId,
    server,
  } = useSelector((state) => state.stores);

  useEffect(() => {
    tele.ready();
    tele.expand();
    const { user } = tele.initDataUnsafe;
    dispatch(setCurrentCustomer(user));
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [locationPage]);

  useEffect(() => {
    if (
      storesStatus === REDUX_ASYNC_FETCH_STATUS.succeeded &&
      stores.length > 0
    ) {
      const currentStore = stores[stores.length - 1];
      dispatch(setCurrentStore(currentStore.id));
    }
  }, [storesStatus, stores, dispatch]);

  useEffect(() => {
    if (!!currentStoreId) {
      dispatch(fetchCategories({ storeId: currentStoreId, server }));
      dispatch(fetchProducts({ storeId: currentStoreId, server }));
    }
  }, [currentStoreId, dispatch, server]);

  useEffect(() => {
    if (!!customerId) dispatch(fetchCartItems({ customerId, server }));
  }, [customerId, server]);

  return (
    <>
      <Routes>
        <Route path="" element={<Home />} />
        <Route path="/auth" element={<Auth />} />
        <Route path="/detail/:id" element={<Detail />} />{' '}
        <Route path="/category/:id" element={<Category />} />
        <Route path="/products" element={<Products />} />
        <Route path="/saved" element={<Saved />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/checkout" element={<Checkout />} />
        {/* payments start */}
        <Route path="/cashapp" element={<CashApp />} />
        {/* payments end */}
      </Routes>
    </>
  );
});
