import {
  DEFAULT_PAYMENT_METHOD_LIMIT,
  DEFAULT_PAYMENT_METHOD_OFFSET,
} from '../constants';
import apiClient from '../lib/apiClient';
import apiRoutes from '../lib/routes/api';

export async function getCartItemsAPI(
  customerId,
  server,
  offset = DEFAULT_PAYMENT_METHOD_OFFSET,
  limit = DEFAULT_PAYMENT_METHOD_LIMIT,
) {
  const url = apiRoutes.apiCustomerCartItemsPath(customerId);
  const params = new URLSearchParams({
    server,
    offset,
    limit,
  });
  const result = await apiClient.get(url, params);
  return !!result ? result : [];
}

export async function addCartItemAPI(
  customerId,
  server,
  productId,
  productVariationId,
) {
  const url = apiRoutes.apiCustomersAddCartItemPath(customerId);

  const params = new URLSearchParams({
    server,
    product_id: productId,
  });
  if (!!productVariationId)
    params.append('product_variation_id', productVariationId);

  const result = await apiClient.post(url, params);
  return result;
}

export async function removeCartItemAPI(customerId, _, cartItemId) {
  const url = apiRoutes.apiCustomersRemoveCartItemrPath(customerId, cartItemId);
  const result = await apiClient.httpDelete(url);
  return result;
}
