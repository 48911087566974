import { Profiler } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { store } from './redux/store';
import { Provider as ReduxProvider } from 'react-redux';
import App from './App';
import { fetchStores } from './redux/slices/storesSlice';
import { DEFAULT_SERVER_NAME } from './constants';
const container = document.getElementById('root');
const root = createRoot(container);
store.dispatch(fetchStores(DEFAULT_SERVER_NAME));

root.render(
  <Profiler id="App">
    <ReduxProvider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ReduxProvider>
  </Profiler>,
);
