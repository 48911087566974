import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Header from '../components/Shared/Header';
import ProductItem from '../components/Product/ProductItem';
import Search from '../components/Shared/Search';
import Browse from '../components/Shared/Browse';

import {
  selectAllCategories,
  getFetchingCategoriesStatus,
} from '../redux/slices/categorySlice';
import {
  getFetcingPruductsStatus,
  selectAllProducts,
} from '../redux/slices/productsSlice';

export default function Home() {
  const categories = useSelector(selectAllCategories);
  const fetchingCategoriesStatus = useSelector(getFetchingCategoriesStatus);
  const products = useSelector(selectAllProducts);
  const fetchingProductsStatus = useSelector(getFetcingPruductsStatus);

  const [isLoadingCategories, setIsLoadingCategories] = useState(true);
  const [isLoadingProducts, setIsLoadingProducts] = useState(true);

  useEffect(() => {
    setIsLoadingCategories(
      fetchingCategoriesStatus === 'idle' ||
        fetchingCategoriesStatus === 'loading',
    );

    setIsLoadingProducts(
      fetchingProductsStatus === 'idle' || fetchingProductsStatus === 'loading',
    );
  }, [fetchingCategoriesStatus, fetchingProductsStatus]);

  const [lazyLoading, setLazyLoaind] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setLazyLoaind(false);
    }, 2000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      <Header backHome={false} title={<h1>Apple Store</h1>} />
      <div className="product">
        <div className="auto__container">
          <div className="product__inner">
            {lazyLoading || isLoadingCategories
              ? [1, 2, 3, 4].map((_, index) => {
                  return (
                    <div className="productCat" key={index}>
                      <div className="productCat__image placeholder"></div>
                      <div className="productCat__content">
                        <h4 className="placeholder">{}</h4>
                        <button className="button placeholder"></button>
                      </div>
                    </div>
                  );
                })
              : categories.map((item, index) => {
                  return (
                    <Link
                      to={`category/${item.id}`}
                      className="productCat"
                      key={index}
                    >
                      <div className="productCat__image">
                        {!!item.image && (
                          <img src={item.image[0]} alt="category" />
                        )}
                      </div>
                      <div className="productCat__content">
                        <h4>{item.title}</h4>
                        <button className="button white">View shop</button>
                      </div>
                    </Link>
                  );
                })}
          </div>
          <div className="filterBtns">
            <Browse categories={categories} />
            <Search categories={categories} />
          </div>
          {/* <div className="product__inner">
            <div className="product__head">
              <h4>Continue shopping</h4>
              <Link className="product__head-link" to="">
                See all
              </Link>
            </div>
            <div className="product__slider">
              <Slider {...settings}>
                {VisitedModul.map((item, index) => {
                  return (
                    <ProductItem
                      itemData={item}
                      setModul={() => setModul(VisitedModul)}
                      form={form}
                      updateForm={updateForm}
                      key={index}
                      {...item}
                    />
                  );
                })}
              </Slider>
            </div>
          </div> */}
          {/* <div className="product__inner">
            <div className="product__head">
              <h4>Suggested for you</h4>
              <Link className="product__head-link" to="">
                See all
              </Link>
            </div>
            <div className="product__row">
              {SuggestedModul.map((item, index) => {
                return (
                  <ProductItem
                    setModul={() => setModul(SuggestedModul)}
                    itemData={item}
                    form={form}
                    updateForm={updateForm}
                    key={index}
                    {...item}
                  />
                );
              })}
            </div>
          </div> */}
          <div className="product__inner">
            <div className="product__head">
              <h4>More from this shop</h4>
              <Link className="product__head-link" to="/products">
                See all
              </Link>
            </div>
            {lazyLoading || isLoadingProducts ? (
              <div className="product__row">
                {[1, 2, 3, 4].map((item, index) => {
                  return (
                    <div className="productItem" key={index}>
                      <div className="productItem__image placeholder"></div>
                      <div className="productItem__content">
                        <h6 className="placeholder">{}</h6>
                        <div className="productItem__content-bot">
                          <div className="productItem__price placeholder"></div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="product__row">
                {products.map((item) => {
                  return <ProductItem key={item.id} {...item} />;
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
