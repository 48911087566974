import apiClient from '../lib/apiClient';
import apiRoutes from '../lib/routes/api';
import { DEFAULT_STORE_ID } from '../constants';

export async function getCategories(storeId, server) {
  const url = apiRoutes.apiCategoriesPath(storeId);
  const params = new URLSearchParams({ server });
  const result = await apiClient.get(url, params);
  return !!result ? result : [];
}

export async function getCategory(categoryId, storeId = DEFAULT_STORE_ID) {
  const url = apiRoutes.apiCategoryPath(storeId, categoryId);
  const result = await apiClient.get(url);
  return result;
}

export async function createCategory(
  data,
  imageUrl,
  storeId = DEFAULT_STORE_ID,
) {
  const { title, description } = data;
  const url = apiRoutes.apiCategoryCreatePath(storeId);
  const params = new URLSearchParams({
    title,
    description,
  });

  const formData = new FormData();
  formData.append('image', imageUrl);
  const result = await apiClient.post(url, params, formData);
  return result;
}

export async function updateCategory(
  categoryId,
  data,
  imageUrl,
  storeId = DEFAULT_STORE_ID,
) {
  const { title, description } = data;
  const url = apiRoutes.apiCategoryUpdatePath(storeId, categoryId);
  const params = new URLSearchParams({
    title,
    description,
  });

  const formData = new FormData();
  formData.append('image', imageUrl);
  const result = await apiClient.httpPatch(url, params, formData);
  return result;
}

export async function deleteCategory(categoryId, storeId = DEFAULT_STORE_ID) {
  const url = apiRoutes.apiCategoryDeletePath(storeId, categoryId);
  const result = await apiClient.httpDelete(url);
  return !!result ? 'deleted' : result;
}
