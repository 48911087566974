import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Header from '../components/Shared/Header';
import ProductItem from '../components/Product/ProductItem';

import {
  selectSavedItems,
  selectPromotedItems,
} from '../redux/slices/savedSlice';

import {
  selectAllProducts,
  getFetcingPruductsStatus,
} from '../redux/slices/productsSlice';

export default function Saved() {
  const savedItems = useSelector(selectSavedItems);
  const promotedItems = useSelector(selectPromotedItems);

  const allProducts = useSelector(selectAllProducts);
  const fetchingProductsStatus = useSelector(getFetcingPruductsStatus);
  const savedProducts = allProducts.filter((product) =>
    savedItems.includes(product.id),
  );

  const promotedProducts = allProducts.filter((product) =>
    promotedItems.includes(product.id),
  );

  const [isLoadingProducts, setIsLoadingProducts] = useState(true);

  useEffect(() => {
    setIsLoadingProducts(
      fetchingProductsStatus === 'idle' || fetchingProductsStatus === 'loading',
    );
  }, [fetchingProductsStatus]);

  return (
    <>
      <Header backHome={true} title={<h4>Saved</h4>} />
      <div className="product">
        <div className="auto__container">
          <div className="product__inner">
            <div className="product__head">
              <h4>Saved products</h4>
            </div>
            {isLoadingProducts ? (
              <div className="product__row">
                {[1, 2, 3, 4, 5, 6, 7, 8].map((_, index) => {
                  return (
                    <div className="productItem" key={index}>
                      <div className="productItem__image placeholder"></div>
                      <div className="productItem__content">
                        <h6 className="placeholder">{}</h6>
                        <div className="productItem__content-bot">
                          <div className="productItem__price placeholder"></div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="product__row">
                {savedProducts.map((product) => (
                  <ProductItem key={product.id} {...product} />
                ))}
              </div>
            )}
            <div className="product__inner">
              <div className="product__head">
                <h4>Suggested Products</h4>
                <Link className="product__head-link" to="/products">
                  See all
                </Link>
              </div>
              {isLoadingProducts ? (
                <div className="product__row">
                  {[1, 2, 3, 4, 5, 6, 7, 8].map((_, index) => {
                    return (
                      <div className="productItem" key={index}>
                        <div className="productItem__image placeholder"></div>
                        <div className="productItem__content">
                          <h6 className="placeholder">{}</h6>
                          <div className="productItem__content-bot">
                            <div className="productItem__price placeholder"></div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="product__row">
                  {promotedProducts.map((promotedProduct) => {
                    return (
                      <ProductItem
                        key={promotedProduct.id}
                        {...promotedProduct}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
