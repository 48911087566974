import React, { useEffect, useState, memo, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Header from '../components/Shared/Header';

import ProductItem from '../components/Product/ProductItem';
import CartItem from '../components/CartItem/CartItem';

import { selectAllConsistentItems } from '../redux/slices/cartSlice';

import {
  selectAllProducts,
  getFetcingPruductsStatus,
} from '../redux/slices/productsSlice';

import { selectPromotedItems } from '../redux/slices/savedSlice';

export default memo(function Cart() {
  const navigate = useNavigate();

  const cartConsistentItems = useSelector(selectAllConsistentItems);
  const allProducts = useSelector(selectAllProducts);
  const promotedItems = useSelector(selectPromotedItems);
  const fetchingProductsStatus = useSelector(getFetcingPruductsStatus);

  const [lazyLoading, setLazyLoaind] = useState(true);

  const itemsWithQuantity = useMemo(
    () =>
      cartConsistentItems.reduce((acc, item) => {
        const index = acc.findIndex(
          (el) => String(el.productId) === String(item.product.id),
        );

        if (index < 0) {
          acc.push({
            productId: item.product.id,
            quantity: 1,
            product: item.product,
          });
          return acc;
        }

        acc[index].quantity += 1;
        return acc;
      }, []),
    [cartConsistentItems],
  );

  const promotedProducts = useMemo(
    () => allProducts.filter((product) => promotedItems.includes(product.id)),
    [allProducts, promotedItems],
  );

  const total = useMemo(
    () =>
      cartConsistentItems.reduce((acc, { product }) => {
        return acc + product.default_price;
      }, 0),
    [cartConsistentItems],
  );

  const isLoadingProducts =
    fetchingProductsStatus === 'idle' || fetchingProductsStatus === 'loading';

  const checkoutHandler = () => navigate('/checkout');

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLazyLoaind(false);
    }, 2000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      <Header backHome={true} title={<h4>Cart</h4>} />
      <div className="cart">
        <div className="auto__container">
          <div className="cart__inner">
            {lazyLoading || isLoadingProducts ? (
              <>
                {[0, 1, 2].map((item, index) => {
                  return (
                    <div className="cartItem placeholder" key={index}></div>
                  );
                })}
              </>
            ) : (
              <>
                {itemsWithQuantity.map(({ product, quantity }) => (
                  <CartItem
                    key={product.id}
                    quantity={quantity}
                    product={product}
                  />
                ))}
              </>
            )}

            <div className="product__inner">
              <div className="product__head">
                <h4>Suggested Products</h4>
                <Link className="product__head-link" to="/products">
                  See all
                </Link>
              </div>
              {lazyLoading || isLoadingProducts ? (
                <div className="product__row">
                  {[1, 2, 3, 4, 5, 6, 7, 8].map((item, index) => {
                    return (
                      <div className="productItem" key={index}>
                        <div className="productItem__image placeholder"></div>
                        <div className="productItem__content">
                          <h6 className="placeholder">{}</h6>
                          <div className="productItem__content-bot">
                            <div className="productItem__price placeholder"></div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="product__row">
                  {promotedProducts.map((promotedProduct) => {
                    return (
                      <ProductItem
                        key={promotedProduct.id}
                        {...promotedProduct}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          </div>
          <div className="cartCheck">
            <div className="cartCheck__inner">
              <div className="cartCheck__total">
                <h5>Total price:</h5>
                <p>{total}$</p>
              </div>
              <button onClick={checkoutHandler} className="button prim">
                Checkout
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
