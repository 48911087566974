import {
  DEFAULT_STORE_ID,
  DEFAULT_PAYMENT_METHOD_LIMIT,
  DEFAULT_PAYMENT_METHOD_OFFSET,
} from '../constants';
import apiClient from '../lib/apiClient';
import apiRoutes from '../lib/routes/api';
export async function getStores(server) {
  const url = apiRoutes.apiStorePath();
  const params = new URLSearchParams({ server });
  const result = await apiClient.get(url, params);
  return !!result ? result : [];
}

export async function getStore(storeId = DEFAULT_STORE_ID) {
  const url = apiRoutes.apiStoreByIdPath(storeId);
  const result = await apiClient.get(url);
  return result;
}

export async function createStore(data, imageUrl) {
  const { title, description } = data;
  const params = new URLSearchParams({
    title,
    description,
  });
  const formData = new FormData();
  formData.append('image', imageUrl);
  const url = apiRoutes.apiStoreCreatePath();
  const result = await apiClient.post(url, params, formData);
  return result;
}

export async function updateStore(storeId, data, imageUrl) {
  const { title, description } = data;
  const params = new URLSearchParams({
    title,
    description,
  });
  const formData = new FormData();
  formData.append('image', imageUrl);
  const url = apiRoutes.apiStoreUpdatePath(storeId);
  const result = await apiClient.httpPatch(url, params, formData);
  return result;
}

export async function updateStoreSettings(storeId, data) {
  const { currency, telegramBotToken } = data;
  const params = new URLSearchParams({
    currency,
    telegram_bot_token: telegramBotToken,
  });
  const url = apiRoutes.apiStoreUpdateSettingsPath(storeId);
  const result = await apiClient.httpPatch(url, params);
  return result;
}

export async function deleteStore(storeId) {
  const url = apiRoutes.apiStoreDeletePath(storeId);
  const result = await apiClient.httpDelete(url);
  return result;
}

export async function getPaymentMethods(
  storeId = DEFAULT_STORE_ID,
  offset = DEFAULT_PAYMENT_METHOD_OFFSET,
  limit = DEFAULT_PAYMENT_METHOD_LIMIT,
) {
  const url = apiRoutes.apiPaymentMethodsPath();
  const params = new URLSearchParams({
    store_id: storeId,
    offset,
    limit,
  });
  const result = await apiClient.get(url, params);
  return !!result ? result : [];
}

export async function getPaymentMethod(
  storeId = DEFAULT_STORE_ID,
  paymentMethodId,
) {
  const url = apiRoutes.apiPaymentMethodByStorePath(storeId, paymentMethodId);
  const result = await apiClient.get(url);
  return result;
}

export async function createPaymentMethod(storeId, data) {
  const { type, isActive } = data;
  const params = new URLSearchParams({
    type,
    is_active: isActive,
  });
  const url = apiRoutes.apiPaymentMethodCreateByStorePath(storeId);
  const result = await apiClient.post(url, params);
  return result;
}

export async function updatePaymentMethod(storeId, paymentMethodId, data) {
  const { type, isActive } = data;
  const params = new URLSearchParams({
    type,
    is_active: isActive,
  });
  const url = apiRoutes.apiPaymentMethodUpdateByStorePath(
    storeId,
    paymentMethodId,
  );
  const result = await apiClient.httpPatch(url, params);
  return result;
}

export async function deletePaymentMEthod(storeId, paymentMethodId) {
  const url = apiRoutes.apiPaymentMethodDeleteByStorePath(
    storeId,
    paymentMethodId,
  );
  const result = await apiClient.httpDelete(url);
  return result;
}
