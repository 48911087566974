import styles from './BillingInfoElement.module.css';

export const BillingInfoElement = ({ billingData, selected, onSelected }) => {
  return !!billingData ? (
    <div className={styles.BillingInfoElement}>
      <input
        className={styles.BillingInfoElementInput}
        type="radio"
        name={`billing-${billingData.id}`}
        id={`billing-${billingData.id}`}
        checked={selected}
        onChange={() => onSelected(billingData.id)}
      />
      <label
        className={styles.BillingInfoElementInputLabel}
        htmlFor={`billing-${billingData.id}`}
      >
        <div className={styles.BillingInfoDetailsElement}>
          <span className={styles.BillingInfoDetailsElementTitle}>
            Address:
          </span>
          <span className={styles.BillingInfoDetailsElementData}>
            {`${billingData.line1}${
              !!billingData.line2 && billingData.line2 !== 'null'
                ? ' ' + billingData.line2
                : ''
            }`}
          </span>
        </div>
      </label>

      <details className={styles.BillingInfoElementDetails}>
        <summary></summary>
        <label
          className={styles.BillingInfoElementLabel}
          htmlFor={`billing-${billingData.id}`}
        >
          <div className={styles.BillingInfoDetailsElement}>
            <span className={styles.BillingInfoDetailsElementTitle}>City:</span>
            <span className={styles.BillingInfoDetailsElementData}>
              {billingData.city}
            </span>
          </div>
          <div className={styles.BillingInfoDetailsElement}>
            <span className={styles.BillingInfoDetailsElementTitle}>
              State:
            </span>
            <span className={styles.BillingInfoDetailsElementData}>
              {billingData.state}
            </span>
          </div>
          <div className={styles.BillingInfoDetailsElement}>
            <span className={styles.BillingInfoDetailsElementTitle}>
              Country:
            </span>
            <span className={styles.BillingInfoDetailsElementData}>
              {billingData.country}
            </span>
          </div>
          <div>
            <span className={styles.BillingInfoDetailsElementTitle}>
              Postal Code:
            </span>
            <span className={styles.BillingInfoDetailsElementData}>
              {billingData.postal_code}
            </span>
          </div>
        </label>
      </details>
    </div>
  ) : (
    <div>Unknown billing info</div>
  );
};
