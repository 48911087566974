import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BillingInfoElement } from '../BillingInfoElement/BillingInfoElement';
import { BillingInfoForm } from '../BillingInfoForm/BillingInfoForm';
import { Button } from '../Shared';
import { CheckboxGroup } from '../Shared';

import {
  selectBillingInfo,
  setSelectedBillingId,
} from '../../redux/slices/customersSlice';

import { REDUX_ASYNC_FETCH_STATUS } from '../../constants';

import styles from './BillingInfo.module.css';

export const BillingInfo = ({ digitalOnly, setIsBillingSameAsShipping }) => {
  const dispatch = useDispatch();
  const {
    billingInfo,
    billingInfoStatus,
    selectedBillingId,
    selectedBillingInfo,
  } = useSelector((state) => selectBillingInfo(state));

  const [showForm, setShowForm] = useState(false);
  const [showAll, setShowAll] = useState(false);

  const [isSameAsShipping, setIsSameAsShipping] = useState(false);

  const sameAsShippingCheckHandler = () => {
    setIsSameAsShipping(!isSameAsShipping);
    setIsBillingSameAsShipping(!isSameAsShipping);
  };

  return (
    <div className={styles.container}>
      {!isSameAsShipping && billingInfo?.length > 0 && (
        <div className={styles.showAllButtonContainer}>
          <button
            className={styles.showAllButton}
            onClick={() => setShowAll(!showAll)}
          >
            {showAll ? 'hide unselected' : 'show all'}
          </button>
        </div>
      )}
      <h4>Select billing info</h4>
      {!digitalOnly && (
        <CheckboxGroup
          labelValue={'Same as shipping'}
          checked={isSameAsShipping}
          onChange={sameAsShippingCheckHandler}
        />
      )}

      {!isSameAsShipping &&
        showAll &&
        billingInfo?.length > 0 &&
        billingInfo.map((billing) => (
          <BillingInfoElement
            key={billing.id}
            billingData={billing}
            selected={billing.id === selectedBillingId}
            onSelected={(id) => dispatch(setSelectedBillingId(id))}
          ></BillingInfoElement>
        ))}
      {!isSameAsShipping && !showAll && selectedBillingInfo && (
        <BillingInfoElement billingData={selectedBillingInfo} selected />
      )}
      {!isSameAsShipping &&
        !showForm &&
        (!billingInfo || billingInfo.length === 0) && (
          <div className={styles.noInfo}>
            No billing info available, please add.
          </div>
        )}

      {billingInfoStatus === REDUX_ASYNC_FETCH_STATUS.falied && (
        <div className={styles.error}>Failed to load billing info.</div>
      )}
      {!isSameAsShipping &&
        billingInfoStatus === REDUX_ASYNC_FETCH_STATUS.loading && (
          <div className="detail__body">
            {[1].map((_, index) => {
              return <div className="tabItem placeholder" key={index}></div>;
            })}
          </div>
        )}
      {!isSameAsShipping && (
        <div>
          {showForm && <BillingInfoForm onClose={() => setShowForm(false)} />}
          {!showForm && (
            <div className={styles.buttonContainer}>
              <Button onClick={() => setShowForm(!showForm)}>
                Add billing info
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
