import React, { useEffect, useState, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { trashIcon } from '../../components/Shared/SVG';

import {
  getConsistentCartItemsState,
  sendAddedCartItem,
  sendRemovedCartItem,
  selectCartItemsIdsByProductId,
} from '../../redux/slices/cartSlice';
import { getCurrentUserId } from '../../redux/slices/customersSlice';

import { REDUX_ASYNC_FETCH_STATUS } from '../../constants';

const CartItem = memo(({ quantity, product }) => {
  const dispatch = useDispatch();

  const fetchingStatus = useSelector(getConsistentCartItemsState);
  const { server } = useSelector((state) => state.stores);
  const customerId = useSelector(getCurrentUserId);
  const currentProductInCartIds = useSelector((state) =>
    selectCartItemsIdsByProductId(state, product.id),
  );

  const [updating, setUpdating] = useState(
    fetchingStatus !== REDUX_ASYNC_FETCH_STATUS.loading,
  );

  const addToCartHandler = () => {
    setUpdating(true);
    dispatch(sendAddedCartItem({ customerId, productId: product.id, server }));
  };

  const removeFromCartHandler = () => {
    setUpdating(true);
    const cartItemIdToRemove = [...currentProductInCartIds].sort(
      (a, b) => b - a,
    )[0];
    dispatch(
      sendRemovedCartItem({
        customerId,
        cartItemId: cartItemIdToRemove,
        server,
      }),
    );
  };

  useEffect(() => {
    if (fetchingStatus !== REDUX_ASYNC_FETCH_STATUS.loading)
      setTimeout(() => setUpdating(false), 500);
  }, [fetchingStatus]);

  return (
    <div className="cartItem">
      <div className="cartItem__image">
        {product?.images[0] ? (
          <img src={product?.images[0]} alt="product" />
        ) : (
          'no photo'
        )}
      </div>
      <div className="cartItem__info">
        <div className="cartItem__info-text">
          <h6>{product?.title}</h6>
          <p>
            {quantity *
              (product?.price ? product.price : product.default_price)}
            $
          </p>
        </div>
        <div className="spinbox">
          {quantity === 1 ? (
            <button
              className="spinbox__trash"
              type="button"
              onClick={removeFromCartHandler}
            >
              {trashIcon}
            </button>
          ) : (
            <button
              className={`spinbox__crement`}
              onClick={removeFromCartHandler}
              disabled={updating}
            >
              -
            </button>
          )}

          <div className="spinbox__numb">
            {updating ? (
              <ClipLoader color="#fff" speedMultiplier={0.5} size={18} />
            ) : (
              quantity
            )}
          </div>
          <button
            className="spinbox__crement"
            onClick={addToCartHandler}
            disabled={updating}
          >
            +
          </button>
        </div>
      </div>
    </div>
  );
});

export default CartItem;
