import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getCategories } from '../../repositories/CategoriesRepository';
const initialState = {
  data: [],
  status: 'idle',
  error: null,
};

export const fetchCategories = createAsyncThunk(
  'categories/fetchCategories',
  async ({ storeId, server }) => {
    try {
      const categories = await getCategories(storeId, server);
      return categories;
    } catch (error) {
      return error.message;
    }
  },
);

export const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    addCategory: (state, action) => {
      state.data.push(action.payload);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error;
      });
  },
});

export const selectAllCategories = (state) => state.categories.data;
export const selectCategoryById = (categoryId) => (state) =>
  state.categories.data.find(
    (category) => String(category.id) === String(categoryId),
  );
export const getFetchingCategoriesStatus = (state) => state.categories.status;

export const { addCategory } = categorySlice.actions;

export default categorySlice.reducer;
