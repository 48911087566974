import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import {
  addCartItemAPI,
  removeCartItemAPI,
  getCartItemsAPI,
} from '../../repositories/CartRepository';

import { store } from '../store';

import { REDUX_ASYNC_FETCH_STATUS } from '../../constants';

const initialState = {
  items: [],
  consistentItems: [],
  status: REDUX_ASYNC_FETCH_STATUS.idle,
  fetchItemsStatus: REDUX_ASYNC_FETCH_STATUS.idle,
  error: null,
  amount: 0,
  totalQuantity: 0,
};

export const fetchCartItems = createAsyncThunk(
  'cart/fetchCartItems',
  async ({ customerId, server }) => {
    const result = await getCartItemsAPI(customerId, server);
    return result;
  },
);

export const sendAddedCartItem = createAsyncThunk(
  'cart/sendAddedCArtItem',
  async ({ customerId, productId, server }) => {
    const result = await addCartItemAPI(customerId, server, productId);
    store.dispatch(fetchCartItems({ customerId, server }));
    if (!result) throw new Error('failed to add a new item');
    return result;
  },
);

export const sendRemovedCartItem = createAsyncThunk(
  'cart/sendRemovedCartItem',
  async ({ customerId, cartItemId, server }) => {
    const result = await removeCartItemAPI(customerId, server, cartItemId);
    store.dispatch(fetchCartItems({ customerId, server }));
    if (!result) throw new Error('failed to remove an item');
    return cartItemId;
  },
);

export const sendRemovedCartItems = createAsyncThunk(
  'cart/sendRemovedCartItems',
  async ({ customerId, server, items }) => {
    const removed = await Promise.all(
      items.map(
        (item) =>
          new Promise(async (resolve, reject) => {
            try {
              resolve({
                id: item.id,
                result: await removeCartItemAPI(customerId, server, item.id),
              });
            } catch (error) {
              resolve({
                id: item.id,
                result: null,
              });
            }
          }),
      ),
    );
    store.dispatch(fetchCartItems({ customerId, server }));
    return removed;
  },
);

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addCartItem: (state, { payload }) => {
      const { id, quantity } = payload;
      const itemToUpdate = state.items.find((item) => item.id === id);

      if (!!itemToUpdate) {
        itemToUpdate.quantity += quantity;
      } else {
        state.items.push(payload);
      }
    },
    removeCartItem: (state, { payload }) => {
      state.consistentItems = state.consistentItems.filter(
        (item) => item.id !== payload,
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendAddedCartItem.pending, (state) => {
        state.status = REDUX_ASYNC_FETCH_STATUS.loading;
      })
      .addCase(sendAddedCartItem.fulfilled, (state, action) => {
        state.status = REDUX_ASYNC_FETCH_STATUS.succeeded;
        state.consistentItems.push(action.payload);
        state.totalQuantity += 1;
      })
      .addCase(sendAddedCartItem.rejected, (state, action) => {
        state.status = REDUX_ASYNC_FETCH_STATUS.falied;
        state.error = action.error.message;
      })
      .addCase(sendRemovedCartItem.pending, (state) => {
        state.status = REDUX_ASYNC_FETCH_STATUS.loading;
      })
      .addCase(sendRemovedCartItem.fulfilled, (state, action) => {
        state.status = REDUX_ASYNC_FETCH_STATUS.succeeded;
        state.consistentItems = state.consistentItems.filter(
          (item) => item.id !== action.payload,
        );
        state.totalQuantity -= 1;
      })
      .addCase(sendRemovedCartItem.rejected, (state, action) => {
        state.status = REDUX_ASYNC_FETCH_STATUS.falied;
        state.error = action.error.message;
      })
      .addCase(sendRemovedCartItems.pending, (state) => {
        state.status = REDUX_ASYNC_FETCH_STATUS.loading;
      })
      .addCase(sendRemovedCartItems.fulfilled, (state, action) => {
        state.status = REDUX_ASYNC_FETCH_STATUS.succeeded;
        const removedItemsId = action.payload.reduce((acc, item) => {
          if (!!item.result) acc.push(item.id);
          return acc;
        }, []);
        state.consistentItems = state.consistentItems.filter((item) =>
          removedItemsId.includes(item.id),
        );
        state.totalQuantity -= removedItemsId.length;
      })
      .addCase(sendRemovedCartItems.rejected, (state, action) => {
        state.status = REDUX_ASYNC_FETCH_STATUS.falied;
        state.error = action.error.message;
      })

      .addCase(fetchCartItems.pending, (state) => {
        state.fetchItemsStatus = REDUX_ASYNC_FETCH_STATUS.loading;
      })
      .addCase(fetchCartItems.fulfilled, (state, action) => {
        state.fetchItemsStatus = REDUX_ASYNC_FETCH_STATUS.succeeded;
        state.consistentItems = action.payload;
        state.totalQuantity = action.payload.length;
      })
      .addCase(fetchCartItems.rejected, (state) => {
        state.fetchItemsStatus = REDUX_ASYNC_FETCH_STATUS.falied;
      });
  },
});

export const selectAllCartItems = (state) => state.cart.items;
export const selectAllConsistentItems = (state) => state.cart.consistentItems;
export const getConsistentCartItemsState = (state) =>
  state.cart.fetchItemsStatus;
export const selectCartTotalQuantity = (state) => state.cart.totalQuantity;
export const getCartLoadingStatus = (state) => state.cart.status;
export const getCartLoadingError = (state) => state.cart.error;

export const selectCartItemById = createSelector(
  [selectAllCartItems, (_, selectedItemId) => selectedItemId],
  (items, selectedItemId) =>
    items.find((item) => String(item.id) === String(selectedItemId)),
);

export const selectCartItemsByProductId = createSelector(
  [selectAllConsistentItems, (_, productId) => productId],
  (items, productId) => {
    return items.filter(
      (item) => String(item.product.id) === String(productId),
    );
  },
);

export const selectCartItemsIdsByProductId = createSelector(
  [selectCartItemsByProductId, (_, productId) => productId],
  (items) => {
    return items.map((item) => item.id);
  },
);

export const selectCartConsistentItemsByProductId = createSelector(
  [selectAllConsistentItems, (_, productId) => productId],
  (items, productId) => {
    return items.reduce(
      (acc, item) => {
        if (String(item.product.id) === String(productId)) {
          if (!acc.product) acc.product = item.product;
          acc.quantity += 1;
        }
        return acc;
      },
      { product: null, quantity: 0 },
    );
  },
);

export const { addCartItem, removeCartItem } = cartSlice.actions;

export default cartSlice.reducer;
