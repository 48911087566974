import { DEFAULT_GATEWAY_HOST } from '../../../constants';
export function apiInvoices() {
  return `${DEFAULT_GATEWAY_HOST}/invoices`;
}

export function apiInvoiceById(invoiceId) {
  return `${apiInvoices()}/${invoiceId}`;
}

export function apiInvoicesItemsByInvoiceId(invoiceId) {
  return `${apiInvoiceById(invoiceId)}/items`;
}

export function apiAddItemToInvoiceItemPath(invoiceId) {
  return `${apiInvoiceById(invoiceId)}/add_invoice_item`;
}

const apiInvoiceRoutes = {
  apiInvoices,
  apiInvoiceById,
  apiInvoicesItemsByInvoiceId,
  apiAddItemToInvoiceItemPath,
};

export default apiInvoiceRoutes;
