import React from "react";
import { chevronRight } from "../../../../components/Shared/SVG";

export default function Details({ tab, setTab }) {
  return (
    <div className="tabItem">
      <div
        className={"tabItem__btn " + (tab === "details" ? "active" : "")}
        onClick={setTab}
      >
        Details {chevronRight}
      </div>
      <div
        className={"tabItem__content " + (tab === "details" ? "active" : "")}
      >
        <p>
          <strong>Brand</strong> - Apple
        </p>
      </div>
    </div>
  );
}
