import { DEFAULT_GATEWAY_HOST } from '../../../constants';
export function apiProductsPath() {
  return `${DEFAULT_GATEWAY_HOST}/products`;
}

const apiProductsRoutes = {
  apiProductsPath,
};

export default apiProductsRoutes;
