import React, { useEffect, useState } from 'react';
import { arrowLeft, chevronBottom, chevronRight } from './SVG';
import { useLocation } from 'react-router-dom';
const sortByList = [
  {
    id: '1',
    value: 'Suggested products',
    default: true,
  },
  {
    id: '2',
    value: 'Newest',
  },
  {
    id: '3',
    value: 'Price: low to high',
  },
  {
    id: '4',
    value: 'Price: high to low',
  },
];
const priceList = [
  {
    id: '1',
    value: 'All prices',
    default: true,
  },
  {
    id: '2',
    value: 'Under 25$',
  },
  {
    id: '3',
    value: '25$ - 50$',
  },
  {
    id: '4',
    value: '50 - 100$',
  },
  {
    id: '5',
    value: '100$ - 200$',
  },
  {
    id: '6',
    value: '200$ and above',
  },
];
export default function Filter({ filters, setFilters, defaultFilters }) {
  const [tab, setTab] = useState(null);
  const [addClass, setAddClass] = useState(null);
  const updateFilters = (data) => {
    setFilters((filters) => ({ ...filters, ...data }));
  };
  console.log(defaultFilters);
  const closeFilters = (e) => {
    if (e.target === e.currentTarget) setTab(false);
  };
  const location = useLocation();

  useEffect(() => {
    if (tab) {
      document.body.classList.add('active');
    } else {
      document.body.classList.remove('active');
    }
  }, [tab]);
  useEffect(() => {
    setTab(false);
    document.body.classList.remove('active');
  }, [location]);

  useEffect(() => {
    const reveal = () => {
      setTimeout(() => {
        setAddClass(tab);
      }, 50);
    };
    reveal();
  }, [tab]);

  return (
    <div className="filter">
      <div
        className={
          'filterBtn ' +
          (filters.onSale || filters.inStock || !filters.sortBy?.default
            ? 'active'
            : '')
        }
        onClick={() => setTab('main')}
      >
        Sort & filter {chevronBottom}
      </div>
      {tab === 'main' && (
        <div
          className={'filterContent ' + (addClass === 'main' ? 'active' : '')}
          onClick={closeFilters}
        >
          <div className="filterContent__inner">
            <div className="filterContent__header">
              <h5>Sort & filter</h5>
              {!filters.sortBy?.default || filters.onSale || filters.inStock ? (
                <button
                  className="clear"
                  onClick={() => updateFilters({ ...defaultFilters })}
                >
                  Clear
                </button>
              ) : (
                ''
              )}
            </div>
            <div className="filterContent__body">
              <div
                className="filterContent__row"
                onClick={() => setTab('sortBy')}
              >
                <h5>Sort by</h5>
                <p className={!filters.sortBy?.default ? 'active' : ''}>
                  {filters.sortBy.value}
                  {chevronRight}
                </p>
              </div>

              <div
                className="filterContent__row"
                onClick={() => setTab('price')}
              >
                <h5>Price</h5>
                <p className={!filters.price?.default ? 'active' : ''}>
                  {filters.price.value}
                  {chevronRight}
                </p>
              </div>
              <div className="filterContent__row">
                <h5>On Sale</h5>
                <div className="check">
                  <input
                    type="checkbox"
                    checked={filters.onSale}
                    onChange={(e) =>
                      updateFilters({
                        onSale: e.target.checked,
                      })
                    }
                  />
                  <label htmlFor=""></label>
                </div>
              </div>
              <div className="filterContent__row">
                <h5>In Stock</h5>
                <div className="check">
                  <input
                    type="checkbox"
                    checked={filters.inStock}
                    onChange={(e) =>
                      updateFilters({
                        inStock: e.target.checked,
                      })
                    }
                  />
                  <label htmlFor=""></label>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {tab === 'sortBy' && (
        <div
          className={'filterContent ' + (addClass === 'sortBy' ? 'active' : '')}
          onClick={closeFilters}
        >
          <div className="filterContent__inner">
            <div className="filterContent__header">
              <div className="back" onClick={() => setTab('main')}>
                {arrowLeft}
              </div>
              <h5>Sort by</h5>
            </div>
            <div className="filterContent__body">
              {sortByList.map((item, index) => {
                return (
                  <div className="filterContent__row" key={index}>
                    <h5
                      className={item?.default ? 'bold' : 'reg'}
                      onClick={() => {
                        updateFilters({ sortBy: item });
                        setTab('main');
                      }}
                    >
                      {item.value}
                    </h5>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
      {tab === 'price' && (
        <div
          className={'filterContent ' + (addClass === 'price' ? 'active' : '')}
          onClick={closeFilters}
        >
          <div className="filterContent__inner">
            <div className="filterContent__header">
              <div className="back" onClick={() => setTab('main')}>
                {arrowLeft}
              </div>
              <h5>Price</h5>
            </div>
            <div className="filterContent__body">
              {priceList.map((item, index) => {
                return (
                  <div className="filterContent__row" key={index}>
                    <h5
                      className={item?.default ? 'bold' : 'reg'}
                      onClick={() => {
                        updateFilters({ price: item });
                        setTab('main');
                      }}
                    >
                      {item.value}
                    </h5>
                    <div className="radio">
                      <input
                        type="radio"
                        name="price"
                        checked={item.id === filters.price.id}
                        onChange={() => updateFilters({ price: item })}
                      />
                      <label htmlFor=""></label>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
