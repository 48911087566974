import { DEFAULT_GATEWAY_HOST } from '../../../constants';
export function apiCategoriesPath(storeId) {
  if (!storeId || storeId.length === 0) {
    return `${DEFAULT_GATEWAY_HOST}/stores/0/categories/`;
  }
  return `${DEFAULT_GATEWAY_HOST}/stores/${storeId}/categories/`;
}

export function apiCategoryPath(storeId, categoryId) {
  return `${apiCategoriesPath(storeId)}${
    !categoryId || categoryId.length === 0 ? '0' : categoryId
  }`;
}

export function apiCategoryCreatePath(storeId) {
  return `${apiCategoriesPath(storeId)}create`;
}

export function apiCategoryUpdatePath(storeId, categoryId) {
  return `${apiCategoryPath(storeId, categoryId)}/update`;
}

export function apiCategoryDeletePath(storeId, categoryId) {
  return `${apiCategoryPath(storeId, categoryId)}/delete`;
}

const apiCategoriesRoutes = {
  apiCategoriesPath,
  apiCategoryPath,
  apiCategoryCreatePath,
  apiCategoryUpdatePath,
  apiCategoryDeletePath,
};

export default apiCategoriesRoutes;
