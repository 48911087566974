import { DEFAULT_GATEWAY_HOST } from '../../../constants';
export function apiCustomersPath() {
  return `${DEFAULT_GATEWAY_HOST}/customers`;
}

export function apiCustomerByIdPath(customerId) {
  return `${apiCustomersPath()}/${customerId}`;
}

export function apiCreateCustomerPath() {
  return `${apiCustomersPath()}/create`;
}

export function apiCustomerCartItemsPath(customerId) {
  return `${apiCustomersPath()}/${customerId}/cart_items`;
}

export function apiCustomersAddCartItemPath(customerId) {
  return `${apiCustomersPath()}/${customerId}/add_cart_item`;
}

export function apiCustomersRemoveCartItemrPath(customerId, cartItemId) {
  return `${apiCustomersPath()}/${customerId}/cart/${cartItemId}/delete`;
}

export function apiCustomersInvoicesByCustomerId(customerId) {
  return `${apiCustomersPath()}/${customerId}/invoices`;
}

export function apiCustomersCreateInvoicePath(customerId) {
  return `${apiCustomersInvoicesByCustomerId(customerId)}/create`;
}

export function apiGetBillingInfo(customerId) {
  return `${apiCustomerByIdPath(customerId)}/billing_info`;
}
export function apiGetShippingInfo(customerId) {
  return `${apiCustomerByIdPath(customerId)}/shipping_info`;
}

export function apiCustomersCreateBillingInfoPath(customerId) {
  return `${apiCustomerByIdPath(customerId)}/create_billing_info`;
}
export function apiCustomersCreateShippingInfoPath(customerId) {
  return `${apiCustomerByIdPath(customerId)}/create_shipping_info`;
}

const apiCustomersRoutes = {
  apiCustomersPath,
  apiCustomerCartItemsPath,
  apiCustomersAddCartItemPath,
  apiCustomersRemoveCartItemrPath,
  apiCustomersInvoicesByCustomerId,
  apiCustomersCreateInvoicePath,
  apiCreateCustomerPath,
  apiGetBillingInfo,
  apiGetShippingInfo,
  apiCustomersCreateBillingInfoPath,
  apiCustomersCreateShippingInfoPath,
};

export default apiCustomersRoutes;
