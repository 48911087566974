import apiCategoriesRoutes from "./apiCategoriesRoutes";
import apiStoresRoutes from "./apiStoresRoutes";
import apiProductsRoutes from "./apiProductsRoutes";
import apiCustomersRoutes from "./apiCustomerRoutes";
import apiPromocodesRoutes from "./apiPromocodesRoutes";
import apiInvoiceRoutes from "./apiInvoiceRoutes";
const apiRoutes = {
  ...apiCategoriesRoutes,
  ...apiStoresRoutes,
  ...apiProductsRoutes,
  ...apiCustomersRoutes,
  ...apiPromocodesRoutes,
  ...apiInvoiceRoutes,
};

export default apiRoutes;
