import React from 'react';
import { chevronRight } from '../../../../components/Shared/SVG';

export default function Shipping({ tab, setTab }) {
  return (
    <div className="tabItem">
      <div
        className={'tabItem__btn ' + (tab === 'shipping' ? 'active' : '')}
        onClick={setTab}
      >
        Shipping & Returns {chevronRight}
      </div>
      <div
        className={'tabItem__content ' + (tab === 'shipping' ? 'active' : '')}
      >
        <h6>Shipping</h6>
        <p>$30 Shipping</p>
        <h6>Returns</h6>
        <p>Returns accepted within 30 days</p>
        <h6>Cancelation</h6>
        <p>Can't be canceled after purchase</p>
      </div>
    </div>
  );
}
