import {
  DEFAULT_PAYMENT_METHOD_LIMIT,
  DEFAULT_PAYMENT_METHOD_OFFSET,
} from '../constants';
import apiClient from '../lib/apiClient';
import apiRoutes from '../lib/routes/api';

export async function getProducts(
  storeId,
  server,
  categoryId,
  offset = DEFAULT_PAYMENT_METHOD_OFFSET,
  limit = DEFAULT_PAYMENT_METHOD_LIMIT,
) {
  const params = new URLSearchParams({
    server,
    store_id: storeId,
    offset,
    limit,
  });
  if (!!categoryId) params.append('category_id', String(categoryId));
  const url = apiRoutes.apiProductsPath();
  const result = await apiClient.get(url, params);
  return !!result ? result : [];
}
