import React, { useEffect, useState } from 'react';
import { CashAppPay, PaymentForm } from 'react-square-web-payments-sdk';

import styles from './CashApp.module.css';
export default function CashApp({ invoice }) {
  let [keyId, setKey] = useState(0);
  useEffect(() => {
    setKey(keyId++);
  }, [keyId]);

  const createPaymentRequest = () => ({
    countryCode: 'US',
    currencyCode: invoice?.currency.toUpperCase(),
    lineItems: invoice?.items.map(({ product }) => ({
      amount: product.default_price,
      id: product.id,
      label: 'Item to be purchased',
      image: product.images[0],
    })),
    requestBillingContact: false,
    requestShippingContact: false,
    invoice: {
      lable: 'Invoice Number',
      number: invoice?.id,
    },
    total: {
      amount: invoice?.final_total.toFixed(2),
      label: 'Total',
    },
  });

  return (
    <div className={styles.container}>
      <h4 className={styles.h4}>
        {invoice
          ? 'Confirm and arrange payment'
          : 'Invioce for payment is unavailable'}
      </h4>
      {invoice && (
        <div className={styles.PaymentType}>
          <PaymentForm
            applicationId="sandbox-sq0idb-KFmvxONCiGJ3QPEhmr3l6A"
            locationId="LBVNRNR3PX4K0"
            redirectURL="/thanks"
            cardTokenizeResponseReceived={(token, verifiedBuyer) => {
              console.log('Token:', token);
              console.log('Verified Buyer:', verifiedBuyer);
            }}
            createPaymentRequest={createPaymentRequest}
          >
            <CashAppPay
              callbacks={{
                onTokenization: (event) => console.log('event', event),
              }}
            />
          </PaymentForm>
        </div>
      )}
    </div>
  );
}
