import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ShippingInfoElement } from '../ShippingInfoElement/ShippingInfoElement';
import { ShippingInfoForm } from '../ShippingForm/ShippingForm';
import { Button } from '../Shared';

import {
  selectShippingInfo,
  setSelectedShippingId,
} from '../../redux/slices/customersSlice';

import { REDUX_ASYNC_FETCH_STATUS } from '../../constants';

import styles from './ShippingInfo.module.css';

export const ShippingInfo = ({ digitalOnly }) => {
  const dispatch = useDispatch();
  const {
    shippingInfoStatus,
    selectedShippingId,
    shippingInfo,
    selectedShippingInfo,
  } = useSelector((state) => selectShippingInfo(state));

  const [showForm, setShowForm] = useState(false);
  const [showAll, setShowAll] = useState(false);

  return (
    <div className={styles.container}>
      {shippingInfo?.length > 0 && (
        <div className={styles.showAllButtonContainer}>
          <button
            className={styles.showAllButton}
            onClick={() => setShowAll(!showAll)}
          >
            {showAll ? 'hide unselected' : 'show all'}
          </button>
        </div>
      )}
      <h4>Select shipping info</h4>
      {digitalOnly && (
        <div className={styles.noInfo}>
          Only digital products, need no shipping.
        </div>
      )}
      {!digitalOnly &&
        showAll &&
        shippingInfo?.length > 0 &&
        shippingInfo.map((shipping) => (
          <ShippingInfoElement
            key={shipping.id}
            shippingData={shipping}
            selected={shipping.id === selectedShippingId}
            onSelected={(id) => dispatch(setSelectedShippingId(id))}
          ></ShippingInfoElement>
        ))}
      {!digitalOnly && !showAll && selectedShippingInfo && (
        <ShippingInfoElement
          shippingData={selectedShippingInfo}
          selected={true}
        />
      )}
      {!digitalOnly &&
        !showForm &&
        (!shippingInfo || shippingInfo.length === 0) && (
          <div className={styles.noInfo}>
            No shipping info available, please add.
          </div>
        )}

      {shippingInfoStatus === REDUX_ASYNC_FETCH_STATUS.falied && (
        <div className={styles.error}>Failed to load shipping info.</div>
      )}

      {shippingInfoStatus === REDUX_ASYNC_FETCH_STATUS.loading && (
        <div className="detail__body">
          {[1].map((_, index) => {
            return <div className="tabItem placeholder" key={index}></div>;
          })}
        </div>
      )}

      {!digitalOnly && (
        <>
          {showForm && <ShippingInfoForm onClose={() => setShowForm(false)} />}
          {!showForm && (
            <div className={styles.buttonContainer}>
              <Button onClick={() => setShowForm(!showForm)}>
                Add shipping info
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};
