import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { markIcon } from '../Shared/SVG';
import { useDispatch } from 'react-redux';
import { addSavedItem, removeSavedItem } from '../../redux/slices/savedSlice';
import { useSelector } from 'react-redux';
import { selectSavedItems } from '../../redux/slices/savedSlice';
export default function ProductItem({ id, images, off, title, price }) {
  const savedItems = useSelector(selectSavedItems);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSaved, setIsSaved] = useState(false);
  function formatNumber(n) {
    return n.toFixed(2);
  }
  const saveBtn = useRef(null);
  const clickFunc = (e) => {
    if (saveBtn && !saveBtn.current.contains(e.target)) {
      navigate(`/detail/${id}`);
    }
  };
  const savedHandler = () => {
    dispatch(isSaved ? removeSavedItem(id) : addSavedItem(id));
    setIsSaved(!isSaved);
  };

  useEffect(() => {
    setIsSaved(savedItems.includes(id));
  }, [savedItems, id]);

  return (
    <div className="productItem" onClick={clickFunc}>
      <div className="productItem__image">
        <button
          className={isSaved ? 'active' : ''}
          onClick={savedHandler}
          type="button"
          ref={saveBtn}
        >
          {markIcon}
        </button>
        <img src={images[0]} alt="product" />
        {off && <span>{off}% off</span>}
      </div>
      <div className="productItem__content">
        <h6>{title}</h6>
        <div className="productItem__content-bot">
          <div className="productItem__price">
            {off ? (
              <>
                {formatNumber(price - (off / 100) * price)}$
                <span>{formatNumber(price)}$</span>
              </>
            ) : (
              <>{formatNumber(price)}$</>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
