import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { Button, CloseButton, InputGroup } from '../Shared';
import { createShipingInfo } from '../../repositories/CustomerRepository';
import {
  getCurrentUserId,
  fetchShippingInfo,
  setSelectedShippingId,
} from '../../redux/slices/customersSlice';

import styles from './ShippingForm.module.css';

export const ShippingInfoForm = ({ onClose }) => {
  const customerId = useSelector(getCurrentUserId);
  const dispatch = useDispatch();

  const [isSubMitting, setIsSubmitting] = useState(false);
  const [isSubmitError, setIsSubmitError] = useState(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const submitHandler = async (event) => {
    event.preventDefault();
    setIsSubmitError(false);
    setIsSubmitSuccess(false);
    try {
      setIsSubmitting(true);
      const formData = new FormData(event.target);
      const { country, city, addressLine1, addressLine2, postalCode, state } =
        Array.from(formData.entries()).reduce((acc, entry) => {
          console.log(entry);
          if (!acc[entry[0]]) acc[entry[0]] = entry[1];
          return acc;
        }, {});
      const result = await createShipingInfo({
        customerId,
        country,
        city,
        addressLine1,
        addressLine2,
        postalCode,
        state,
      });
      if (!result) throw new Error('failed to submit shipping data');

      dispatch(fetchShippingInfo(customerId));
      dispatch(setSelectedShippingId(result.id));
      setIsSubmitSuccess(true);
      onClose();
    } catch (error) {
      setIsSubmitError(true);
      console.error('submit billing info error', error);
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <form className={styles.form} onSubmit={submitHandler}>
      <h4>New shipping info</h4>
      <div className={styles.closeButtonContainer}>
        <CloseButton onClick={onClose} />
      </div>
      <InputGroup
        name="country"
        labelValue="Country"
        required
        disabled={isSubMitting}
      />
      <InputGroup
        name="city"
        labelValue="City"
        required
        disabled={isSubMitting}
      />
      <InputGroup
        name="addressLine1"
        labelValue="Address line 1"
        required
        disabled={isSubMitting}
      />
      <InputGroup
        name="addressLine2"
        labelValue="Address line 2"
        disabled={isSubMitting}
      />
      <InputGroup
        name="postalCode"
        labelValue="Postal code"
        required
        disabled={isSubMitting}
      />
      <InputGroup
        name="state"
        labelValue="State"
        required
        disabled={isSubMitting}
      />
      <div className={styles.submitButtonContainer}>
        <Button type="submit" disabled={isSubMitting}>
          Submit Shipping Info
        </Button>

        <div
          className={`${styles.submitStatus} ${
            isSubmitError ? styles.error : ''
          } ${isSubmitSuccess ? styles.success : ''}`}
        >
          {' '}
          {isSubMitting && (
            <ClipLoader color="#fff" speedMultiplier={0.5} size={25} />
          )}
          {isSubmitError && 'Failed'}
          {isSubmitSuccess && 'Submitted'}
        </div>
      </div>
    </form>
  );
};
