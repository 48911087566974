import { DEFAULT_GATEWAY_HOST } from '../../../constants';
export function apiPromocodesPath() {
  return `${DEFAULT_GATEWAY_HOST}/promocodes`;
}

const apiPromocodesRoutes = {
  apiPromocodesPath,
};

export default apiPromocodesRoutes;
