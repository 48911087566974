export async function get(url, queryParams, client = fetch) {
  try {
    const response = await client(
      `${url}${queryParams ? '?' + queryParams.toString() : ''}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': 'public',
        },
      },
    );
    if (response.status >= 200 && response.status <= 299)
      return response.json();
    return null;
  } catch (error) {
    console.log('apiClient get error', error);
    return null;
  }
}

export async function post(url, queryParams, formData, client = fetch) {
  try {
    const response = await client(
      `${url}${queryParams ? '?' + queryParams.toString() : ''}`,
      {
        method: 'POST',
        headers: {
          'x-api-key': 'public',
        },
        body: JSON.stringify(formData),
      },
    );
    if (response.status >= 200 && response.status <= 299)
      return response.json();
    return null;
  } catch (error) {
    console.log('apiClient post error', error);
    return null;
  }
}

export async function httpPatch(url, queryParams, formData, client = fetch) {
  try {
    const response = await client(
      `${url}${queryParams ? '?' + queryParams.toString() : ''}`,
      {
        method: 'PATCH',
        headers: {
          'x-api-key': 'public',
        },
        body: JSON.stringify(formData),
      },
    );
    if (response.status === 200) return response.json();
    return null;
  } catch (error) {}
}

export async function httpDelete(url, client = fetch) {
  try {
    const response = await client(url, {
      method: 'DELETE',
      headers: {
        'x-api-key': 'public',
      },
    });
    if (response.status === 200) return response.json();
    return null;
  } catch (error) {
    console.log('apiClient httpDelete error:', error);
    return null;
  }
}

const apiClient = { get, post, httpDelete, httpPatch };

export default apiClient;
