import { createSlice } from '@reduxjs/toolkit';

import { fetchProducts } from './productsSlice';
const initialState = {
  items: [],
  promotedItems: [],
};

export const savedSlice = createSlice({
  name: 'saved',
  initialState,
  reducers: {
    addSavedItem: (state, action) => {
      state.items.push(action.payload);
    },
    removeSavedItem: (state, action) => {
      state.items = state.items.filter((el) => el !== action.payload);
    },
    setPromotedItems: (state, action) => {
      state.promotedItems = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProducts.fulfilled, (state, action) => {
      //TODO: add filtering promoted products by promoted property from backend
      //TODO: remove mock
      const promotedMock = action.payload
        .map((product) => product.id)
        .filter((_, index) => index % Math.floor(1 + Math.random() * 5) === 0);
      const promotedProductIds = action.payload
        .filter((product) => promotedMock.includes(product.id))
        .map((item) => item.id);
      state.promotedItems = promotedProductIds;
    });
  },
});

export const selectSavedItems = (state) => state.saved.items;
export const selectPromotedItems = (state) => state.saved.promotedItems;

export const { addSavedItem, removeSavedItem, setPromotedItems } =
  savedSlice.actions;

export default savedSlice.reducer;
