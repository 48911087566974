import apiClient from '../lib/apiClient';
import apiRoutes from '../lib/routes/api';

export async function getPromocodes(onlyAvailabale = true) {
  const url = apiRoutes.apiPromocodesPath();

  const params = new URLSearchParams({ only_available: onlyAvailabale });
  const result = await apiClient.get(url, params);

  return !!result ? result : [];
}
