import React, { useEffect, useState, memo, useMemo } from 'react';
import { ClipLoader } from 'react-spinners';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { masterIcon, visaIcon } from '../../components/Shared/SVG';
import Header from '../../components/Shared/Header';
import CashApp from '../../components/CashApp/CashApp';
import { BillingInfo, ShippingInfo } from '../../components';

import {
  getConsistentCartItemsState,
  selectAllConsistentItems,
  sendRemovedCartItems,
} from '../../redux/slices/cartSlice';

import { getPaymentMethods } from '../../redux/slices/checkoutSlice';
import { getCurrentStoreId } from '../../redux/slices/storesSlice';
import {
  getCurrentUserId,
  selectBillingInfo,
  selectShippingInfo,
  fetchBillingInfo,
  fetchShippingInfo,
} from '../../redux/slices/customersSlice';

import { getPromocodes } from '../../repositories/PromocodesRepository';

import {
  createInvoiceAPI,
  addCartItemToInvoice,
  getInvoiceById,
} from '../../repositories/CheckoutRepository';

import {
  createShipingInfo,
  createBillingInfo,
} from '../../repositories/CustomerRepository';

import { REDUX_ASYNC_FETCH_STATUS } from '../../constants';

import styles from './Checkout.module.css';

export default memo(function Checkout() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { billingInfo, selectedBillingId, selectedBillingInfo } = useSelector(
    (state) => selectBillingInfo(state),
  );

  const { selectedShippingId, shippingInfo, selectedShippingInfo } =
    useSelector((state) => selectShippingInfo(state));

  const cartConsistentItems = useSelector(selectAllConsistentItems);
  const consistentCartItemsStatus = useSelector(getConsistentCartItemsState);
  const customerId = useSelector(getCurrentUserId);

  const currentStoreId = useSelector(getCurrentStoreId);
  const { server } = useSelector((state) => state.stores);

  const paymentMethods = useSelector((state) => state.checkout.paymentMethods);
  const paymentMethodsStatus = useSelector(
    (state) => state.checkout.paymentMethodStatus,
  );

  const total = useMemo(
    () =>
      cartConsistentItems.reduce((acc, { product }) => {
        return acc + product.default_price;
      }, 0),
    [cartConsistentItems],
  );

  const [isCreatingInvoice, setIsCreatingInvoice] = useState(false);
  const [createInvoiceError, setCreateInvoiceError] = useState(null);
  const [invoiceReadyToPay, setInvoiceReadyToPay] = useState(null);

  const [showCash, setShowCash] = useState(null);
  const [discount, setDiscount] = useState({ type: null, value: 0 });
  const [discountedTotal, setDiscountedTotal] = useState(total);

  const [promoCodeInput, setPromocodeInput] = useState('');
  const [promoCodeStatus, setPromocodeStatus] = useState({
    state: REDUX_ASYNC_FETCH_STATUS.idle,
    data: '',
  });
  const [currentPromoCode, setCurrentPromoCode] = useState(null);
  const [finalOrderAmount, setFinalOrderAmount] = useState(null);

  const [isOnlyDigital, setIsOnlyDigital] = useState(false);
  const [isBillingSameAsShipping, setIsBillingSameAsShipping] = useState(false);

  const [key, setKey] = useState(0);

  const applyPromocodeHandler = async (event) => {
    event.preventDefault();
    setPromocodeStatus(REDUX_ASYNC_FETCH_STATUS.loading);
    setCurrentPromoCode(null);
    try {
      if (promoCodeInput.length === 0) throw new Error('Empty code!');
      const promocodes = await getPromocodes();
      if (promocodes.length === 0) throw new Error('No promo available');
      const currentPromocode = promocodes.find(
        (promocode) => promocode.code === promoCodeInput,
      );
      if (!currentPromocode) {
        setPromocodeStatus((prev) => ({
          ...prev,
          state: REDUX_ASYNC_FETCH_STATUS.falied,
          data: 'Unavailable promo!',
        }));
        return;
      }
      setPromocodeStatus((prev) => ({
        ...prev,
        state: REDUX_ASYNC_FETCH_STATUS.succeeded,
        data: 'Available!',
      }));
      setDiscount((prev) =>
        currentPromocode.value !== undefined
          ? {
              ...prev,
              type: currentPromocode.type,
              value: currentPromocode.value,
            }
          : {
              ...prev,
              type: currentPromocode.type,
              value: 10,
            },
      );
      setCurrentPromoCode(currentPromocode);
      setDiscountedTotal((prev) => {
        const discountAmount =
          currentPromocode.value === undefined
            ? 100
            : currentPromocode.type === 'percent'
            ? (total * currentPromocode.value) / 100
            : currentPromocode.value;
        return total - discountAmount;
      });
    } catch (error) {
      setPromocodeStatus((prev) => ({
        ...prev,
        state: REDUX_ASYNC_FETCH_STATUS.falied,
        data: error.message ? error.message : 'Failed to get promo!',
      }));
    }
  };

  const getSameAsBillingShippingId = async (
    shippingInfo,
    selectedBillingInfo,
  ) => {
    const sameShippingInfo = shippingInfo.find((shipping) => {
      const shippingKeys = Object.keys(shipping).filter(
        (key) => key !== 'id' && key !== 'customer',
      );
      for (const key of shippingKeys) {
        if (shipping[key] !== selectedBillingInfo[key]) return false;
      }
      return true;
    });
    console.log('sameShippingInfo', sameShippingInfo);
    if (!sameShippingInfo) {
      // setSameAsShippingHandlingStatus('Adding...');
      const newShippingInfo = {
        customerId: selectedBillingInfo['customer_id'],
        country: selectedBillingInfo['country'],
        city: selectedBillingInfo['city'],
        addressLine1: selectedBillingInfo['line1'],
        addressLine2: selectedBillingInfo['line2'],
        postalCode: selectedBillingInfo['postal_code'],
        state: selectedBillingInfo['state'],
      };

      console.log('newShippingInfo', newShippingInfo);

      const addedShippingInfo = await createShipingInfo(newShippingInfo);
      dispatch(fetchShippingInfo(customerId));
      return addedShippingInfo.id;
    }
    return sameShippingInfo.id;
  };

  const getSameAsShippingBillingId = async (
    billingInfo,
    selectedShippingInfo,
  ) => {
    console.log(billingInfo, selectedShippingInfo);
    const sameBillingInfo = billingInfo.find((billing) => {
      const billingKeys = Object.keys(billing).filter(
        (key) => key !== 'id' && key !== 'customer',
      );
      for (const key of billingKeys) {
        if (billing[key] !== selectedShippingInfo[key]) return false;
      }
      return true;
    });
    console.log('sameBliingInfo', sameBillingInfo);
    if (!sameBillingInfo) {
      // setSameAsShippingHandlingStatus('Adding...');
      const newBillingInfo = {
        customerId: selectedShippingInfo['customer_id'],
        country: selectedShippingInfo['country'],
        city: selectedShippingInfo['city'],
        addressLine1: selectedShippingInfo['line1'],
        addressLine2: selectedShippingInfo['line2'],
        postalCode: selectedShippingInfo['postal_code'],
        state: selectedShippingInfo['state'],
      };

      console.log('newBillingInfo', newBillingInfo);

      const addedBillingInfo = await createBillingInfo(newBillingInfo);
      dispatch(fetchBillingInfo(customerId));
      return addedBillingInfo.id;
    }
    console.log('sameBillingInfo', sameBillingInfo);
    return sameBillingInfo.id;
  };

  const createInvoiceHandler = async () => {
    console.log(selectedBillingInfo, selectedShippingInfo);
    try {
      setCreateInvoiceError(null);
      setIsCreatingInvoice(true);
      const invoiceData = {
        customerId: customerId,
        server,
        subtotal: total,
        finalTotal: discountedTotal,
        currency: 'usd',
        storeId: currentStoreId,
        paymentMethodId: showCash,
        promocodeId: currentPromoCode ? currentPromoCode.id : null,
        shippingInfoId: isOnlyDigital
          ? await getSameAsBillingShippingId(shippingInfo, selectedBillingInfo)
          : selectedShippingId,
        billingInfoId: isBillingSameAsShipping
          ? await getSameAsShippingBillingId(billingInfo, selectedShippingInfo)
          : selectedBillingId,
      };

      console.log('invoiceData', invoiceData);

      setFinalOrderAmount(discountedTotal);
      const invoice = await createInvoiceAPI(invoiceData);

      const addedToInvoiceCartItems = (
        await Promise.all(
          cartConsistentItems.map(
            (item) =>
              new Promise((resolve) => {
                try {
                  addCartItemToInvoice({
                    invoiceId: invoice.id,
                    server,
                    productId: item.product.id,
                  });
                  resolve(item);
                } catch (error) {
                  resolve(null);
                }
              }),
          ),
        )
      ).filter((item) => !!item);

      dispatch(
        sendRemovedCartItems({
          customerId,
          server,
          items: addedToInvoiceCartItems,
        }),
      );

      const invoiceWithItems = await getInvoiceById({
        invoiceId: invoice.id,
        server,
      });
      console.log('invoiceWithItems', invoiceWithItems);
      setInvoiceReadyToPay(invoiceWithItems);
    } catch (error) {
      console.error(error);
      setCreateInvoiceError(error);
    } finally {
      setIsCreatingInvoice(false);
    }
  };

  useEffect(() => {
    if (currentStoreId !== null) dispatch(getPaymentMethods(currentStoreId));
  }, [currentStoreId, dispatch]);

  useEffect(() => {
    if (paymentMethods.length > 0) {
      const cashAppMethod = paymentMethods.find(
        (method) => method.type === 'cashapp',
      );
      setShowCash(cashAppMethod ? cashAppMethod.id : paymentMethods[0].id);
    }
  }, [paymentMethods]);

  useEffect(() => {
    if (!!customerId) {
      dispatch(fetchBillingInfo(customerId));
      dispatch(fetchShippingInfo(customerId));
    }
  }, [dispatch, customerId]);

  useEffect(() => {
    const firstNonDigital = cartConsistentItems.find(
      ({ product }) => product.shippable,
    );
    setIsOnlyDigital(!firstNonDigital);
  }, [cartConsistentItems]);

  return (
    <>
      <Header backHome={true} title={<h4>Checkout</h4>} />

      <div className="checkout">
        {!customerId ? (
          <div className="auto__container">
            <div className="checkout__inner">
              <h4>Please log in.</h4>
            </div>
          </div>
        ) : cartConsistentItems.length === 0 &&
          (consistentCartItemsStatus === REDUX_ASYNC_FETCH_STATUS.loading ||
            consistentCartItemsStatus === REDUX_ASYNC_FETCH_STATUS.idle) ? (
          <div className="auto__container">
            <div className="checkout__inner">
              <h4>Loading order info...</h4>
            </div>
          </div>
        ) : (
          <div className="auto__container">
            {cartConsistentItems.length === 0 && !invoiceReadyToPay ? (
              <div className="checkout__inner">
                <div className="checkout__more">
                  <h4>Order Info</h4>
                  <div className="checkout__info">
                    <h6>
                      You cart is empty, please add items to create an order
                    </h6>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="checkout__inner">
                  <div className="checkout__more">
                    {!invoiceReadyToPay && (
                      <div className="checkout__info">
                        <h6>Amount:</h6>
                        {consistentCartItemsStatus ===
                          REDUX_ASYNC_FETCH_STATUS.loading && (
                          <ClipLoader
                            color="#fff"
                            speedMultiplier={0.5}
                            size={25}
                          />
                        )}
                        {consistentCartItemsStatus ===
                          REDUX_ASYNC_FETCH_STATUS.succeeded && (
                          <p>{finalOrderAmount ? finalOrderAmount : total} $</p>
                        )}
                      </div>
                    )}
                    {invoiceReadyToPay && (
                      <div className="checkout__info">
                        <h6>Order amount:</h6>
                        <p>{invoiceReadyToPay.final_total} $</p>
                      </div>
                    )}
                    {!invoiceReadyToPay && discount.type && (
                      <div className="checkout__info">
                        <h6>Promo Amount:</h6>
                        <p>{discountedTotal} $</p>
                      </div>
                    )}
                    {!!invoiceReadyToPay && (
                      <>
                        <div className="checkout__info">
                          <h6>Order Id:</h6>
                          <p>{invoiceReadyToPay.id}</p>
                        </div>
                        <div className="checkout__info">
                          <h6>Date:</h6>
                          <p>
                            {new Date(
                              invoiceReadyToPay.created_at,
                            ).toLocaleString()}
                          </p>
                        </div>
                        <div className="checkout__info">
                          <h6>Coupon Code:</h6>
                          <p>
                            {!!currentPromoCode
                              ? currentPromoCode.code
                              : 'No coupon applied'}
                          </p>
                        </div>
                      </>
                    )}
                    {!invoiceReadyToPay && (
                      <div className="checkout__info">
                        <h6>Apply coupon or promocode</h6>
                        {promoCodeStatus.state ===
                          REDUX_ASYNC_FETCH_STATUS.falied && (
                          <div style={{ color: 'red' }}>
                            {promoCodeStatus.data}
                          </div>
                        )}
                        {promoCodeStatus.state ===
                          REDUX_ASYNC_FETCH_STATUS.succeeded && (
                          <div style={{ color: 'green' }}>
                            {promoCodeStatus.data}
                          </div>
                        )}
                        <form
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '15px',
                          }}
                          onSubmit={applyPromocodeHandler}
                        >
                          {promoCodeStatus ===
                            REDUX_ASYNC_FETCH_STATUS.loading && (
                            <ClipLoader
                              color="#fff"
                              speedMultiplier={0.5}
                              size={25}
                            />
                          )}
                          <div
                            className="searchInput"
                            style={{ fontSize: '14px' }}
                          >
                            <input
                              placeholder="promocode"
                              value={promoCodeInput}
                              onChange={(event) =>
                                setPromocodeInput(event.target.value)
                              }
                              style={{
                                padding: '10px 20px',
                                lineHeight: '12px',
                              }}
                            ></input>
                          </div>

                          <button
                            disabled={
                              promoCodeStatus ===
                                REDUX_ASYNC_FETCH_STATUS.loading ||
                              consistentCartItemsStatus ===
                                REDUX_ASYNC_FETCH_STATUS.loading
                            }
                            type="submit"
                            className={`button prim ${
                              promoCodeStatus ===
                              REDUX_ASYNC_FETCH_STATUS.loading
                                ? 'disabled'
                                : ''
                            }`}
                            style={{
                              fontSize: '14px',
                              padding: '10px 14px',
                              borderRadius: '12px',
                            }}
                          >
                            Apply
                          </button>
                        </form>
                      </div>
                    )}
                  </div>

                  {!isOnlyDigital && (
                    <ShippingInfo digitalOnly={isOnlyDigital} />
                  )}

                  <BillingInfo
                    digitalOnly={isOnlyDigital}
                    setIsBillingSameAsShipping={setIsBillingSameAsShipping}
                  />

                  {!invoiceReadyToPay && <h4>Select a payment method</h4>}
                  {paymentMethodsStatus ===
                    REDUX_ASYNC_FETCH_STATUS.loading && (
                    <>
                      {[0, 1, 2].map((item, index) => {
                        return (
                          <div
                            className="cartItem placeholder"
                            key={index}
                          ></div>
                        );
                      })}
                    </>
                  )}
                  {paymentMethods.length > 0 ? (
                    paymentMethods
                      .filter((method) =>
                        invoiceReadyToPay
                          ? method.id === showCash
                          : method.is_active,
                      )
                      .map((method) => {
                        return (
                          <div className="method" key={method.id}>
                            <input
                              type="radio"
                              name="method"
                              checked={showCash === method.id}
                              onChange={() => setShowCash(method.id)}
                              disabled={isCreatingInvoice || invoiceReadyToPay}
                            />
                            <label htmlFor="">
                              <div className="method__text">
                                <span></span>
                                <h6>
                                  {{ 3: 'Cash App', 8: 'CoinBase' }[method.id]}
                                </h6>
                                <p>Powered by Cash</p>
                              </div>
                              <div className="method__side">
                                <div className="method__icons">
                                  <span>{visaIcon}</span>
                                  <span>{masterIcon}</span>
                                </div>
                                <p>+10% gateway fee</p>
                              </div>
                            </label>
                          </div>
                        );
                      })
                  ) : (
                    <div className="method">No available payment methods</div>
                  )}
                </div>
                {!invoiceReadyToPay && (
                  <div className={styles.OrderContainer}>
                    <button
                      className="button prim"
                      onClick={createInvoiceHandler}
                      disabled={
                        (!isBillingSameAsShipping &&
                          !isOnlyDigital &&
                          (!selectedBillingId || !selectedShippingId)) ||
                        (isBillingSameAsShipping && !selectedShippingId) ||
                        (isOnlyDigital && !selectedBillingId) ||
                        isCreatingInvoice ||
                        consistentCartItemsStatus ===
                          REDUX_ASYNC_FETCH_STATUS.loading ||
                        paymentMethodsStatus ===
                          REDUX_ASYNC_FETCH_STATUS.loading
                      }
                    >
                      Create an order (invoice)
                    </button>
                    {createInvoiceError && <p>Error when creating the order</p>}
                  </div>
                )}

                {!!invoiceReadyToPay && (
                  <div className="method">
                    {showCash === 1 && invoiceReadyToPay && (
                      <CashApp
                        key={key}
                        keyId={key}
                        setKey={setKey}
                        invoice={invoiceReadyToPay}
                      />
                    )}
                    {showCash === 'crypto' && (
                      <button type="button" className="button prim">
                        Proceed Payment
                      </button>
                    )}
                  </div>
                )}

                <div className="checkout__footer">
                  <button
                    type="button"
                    onClick={() => navigate(-1)}
                    className="button white"
                  >
                    Back
                  </button>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
});
