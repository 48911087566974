import styles from './ShippingInfoElement.module.css';

export const ShippingInfoElement = ({ shippingData, selected, onSelected }) => {
  return !!shippingData ? (
    <div className={styles.ShippingInfoElement}>
      <input
        className={styles.ShippingInfoElementInput}
        type="radio"
        name={`shipping-${shippingData.id}`}
        id={`shipping-${shippingData.id}`}
        checked={selected}
        onChange={() => onSelected(shippingData.id)}
      />
      <label
        className={styles.ShippingInfoElementInputLabel}
        htmlFor={`shipping-${shippingData.id}`}
      >
        <div className={styles.ShippingInfoDetailsElement}>
          <span className={styles.ShippingInfoDetailsElementTitle}>
            Address:
          </span>
          <span className={styles.ShippingInfoDetailsElementData}>
            {`${shippingData.line1}${
              !!shippingData.line2 && shippingData.line2 !== 'null'
                ? ' ' + shippingData.line2
                : ''
            }`}
          </span>
        </div>
      </label>

      <details className={styles.ShippingInfoElementDetails}>
        <summary></summary>
        <label
          className={styles.ShippingInfoElementLabel}
          htmlFor={`shipping-${shippingData.id}`}
        >
          <div className={styles.ShippingInfoDetailsElement}>
            <span className={styles.ShippingInfoDetailsElementTitle}>
              City:
            </span>
            <span className={styles.ShippingInfoDetailsElementData}>
              {shippingData.city}
            </span>
          </div>
          <div className={styles.ShippingInfoDetailsElement}>
            <span className={styles.ShippingInfoDetailsElementTitle}>
              State:
            </span>
            <span className={styles.ShippingInfoDetailsElementData}>
              {shippingData.state}
            </span>
          </div>
          <div className={styles.ShippingInfoDetailsElement}>
            <span className={styles.ShippingInfoDetailsElementTitle}>
              Country:
            </span>
            <span className={styles.ShippingInfoDetailsElementData}>
              {shippingData.country}
            </span>
          </div>
          <div>
            <span className={styles.ShippingInfoDetailsElementTitle}>
              Postal Code:
            </span>
            <span className={styles.ShippingInfoDetailsElementData}>
              {shippingData.postal_code}
            </span>
          </div>
        </label>
      </details>
    </div>
  ) : (
    <div>Unknown billing info</div>
  );
};
