import React from "react";
import { chevronRight } from "../../../../components/Shared/SVG";

export default function Description({ tab, setTab }) {
  return (
    <div className="tabItem">
      <div
        className={"tabItem__btn " + (tab === "description" ? "active" : "")}
        onClick={setTab}
      >
        Description {chevronRight}
      </div>
      <div
        className={
          "tabItem__content " + (tab === "description" ? "active" : "")
        }
      >
        <p>Super fast charging</p>
        <p>Something else</p>
        <p>maybe That one as well</p>
      </div>
    </div>
  );
}
