import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { getProducts } from '../../repositories/ProductsRepository';

const initialState = {
  data: [],
  status: 'idle',
  error: null,
};

export const fetchProducts = createAsyncThunk(
  'products/fetchProducts',
  async ({ storeId, server }) => {
    try {
      const products = await getProducts(storeId, server);
      return products;
    } catch (error) {
      return error.message;
    }
  },
);

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload.map((product) => ({
          ...product,
          price: product.default_price,
          images: !!product.images
            ? product.images
            : [product.image, product.image],
        }));
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error;
      });
  },
});

export const selectAllProducts = (state) => state.products.data;

export const selectProductsByCategoryId = createSelector(
  [selectAllProducts, (_, selectedCategory) => selectedCategory],
  (products, selectedCategory) =>
    products.filter((product) => {
      return String(product.category_id) === String(selectedCategory);
    }),
);

export const selectProductById = createSelector(
  [selectAllProducts, (_, selectedProductId) => selectedProductId],
  (products, selectedProductId) => {
    return products.find(
      (product) => String(product.id) === String(selectedProductId),
    );
  },
);
export const getFetcingPruductsStatus = (state) => state.products.status;

export default productsSlice.reducer;
