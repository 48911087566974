import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import { useSelector, useDispatch } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { useParams } from 'react-router-dom';

import Header from '../../components/Shared/Header';
import {
  cartStroke,
  markIcon,
  markStroke,
  trashIcon,
} from '../../components/Shared/SVG';
import Tabs from './components/Tabs/Tabs';

import {
  addSavedItem,
  removeSavedItem,
  selectSavedItems,
} from '../../redux/slices/savedSlice';

import {
  getFetcingPruductsStatus,
  selectProductById,
} from '../../redux/slices/productsSlice';

import {
  addCartItem,
  removeCartItem,
  sendAddedCartItem,
  sendRemovedCartItem,
  getCartLoadingStatus,
  getCartLoadingError,
  selectCartConsistentItemsByProductId,
  selectCartItemsIdsByProductId,
} from '../../redux/slices/cartSlice';

import { getCurrentUserId } from '../../redux/slices/customersSlice';

import { REDUX_ASYNC_FETCH_STATUS } from '../../constants';

export default function Detail() {
  const dispatch = useDispatch();

  const { id } = useParams();
  const currentProductId = Number(id);
  const customerId = useSelector(getCurrentUserId);
  const { server } = useSelector((state) => state.stores);

  const currentProduct = useSelector((state) =>
    selectProductById(state, currentProductId),
  );
  const savedItems = useSelector(selectSavedItems);
  const fetchingProductsStatus = useSelector(getFetcingPruductsStatus);

  const cartLoadingStatus = useSelector(getCartLoadingStatus);
  const cartLoadingError = useSelector(getCartLoadingError);

  const currentProductInCartIds = useSelector((state) =>
    selectCartItemsIdsByProductId(state, currentProductId),
  );

  const { quantity } = useSelector((state) =>
    selectCartConsistentItemsByProductId(state, currentProductId),
  );

  const [isLoadingProducts, setIsLoadingProducts] = useState(true);
  const [isSaved, setIsSaved] = useState(false);
  const [lazyLoading, setLazyLoaind] = useState(true);

  const addBtn = useRef(null);
  const saveBtn = useRef(null);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    arrows: false,
  };

  const savedHandler = () => {
    dispatch(
      isSaved
        ? removeSavedItem(currentProductId)
        : addSavedItem(currentProductId),
    );
    setIsSaved(!isSaved);
  };

  const addToCartHandler = () => {
    dispatch(addCartItem({ id: currentProduct.id, quantity: 1 }));
    dispatch(
      sendAddedCartItem({ customerId, server, productId: currentProduct.id }),
    );
  };

  const removeFromCartHandler = () => {
    dispatch(removeCartItem({ id: currentProduct.id, quantity: 1 }));
    const cartItemIdToRemove = currentProductInCartIds.sort((a, b) => b - a)[0];
    dispatch(
      sendRemovedCartItem({
        customerId,
        cartItemId: cartItemIdToRemove,
        server,
      }),
    );
  };

  useEffect(() => {
    if (
      fetchingProductsStatus === 'idle' ||
      fetchingProductsStatus === 'loading'
    ) {
      setIsLoadingProducts(true);
    } else {
      setIsLoadingProducts(false);
    }
  }, [fetchingProductsStatus]);

  useEffect(() => {
    setIsSaved(savedItems.includes(currentProductId));
  }, [savedItems, currentProductId]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLazyLoaind(false);
    }, 2000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      <Header backHome={true} title={<h4>Details</h4>} />
      <div className="detail">
        <div className="auto__container">
          {lazyLoading || isLoadingProducts ? (
            <div className="detail__image ">
              <div className="detail__image-inner placeholder"></div>
            </div>
          ) : (
            <div className="detail__slider">
              <Slider {...settings}>
                {currentProduct.images ? (
                  currentProduct.images.map((itemImage, index) => {
                    return (
                      <div className="detail__image" key={index}>
                        <div className="detail__image-inner">
                          <img src={itemImage} alt="product" />
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="detail__image">
                    <div className="detail__image-inner">
                      <img
                        src={'data:image/jpeg;base64,' + currentProduct?.image}
                        alt="product"
                      />
                    </div>
                  </div>
                )}
              </Slider>
            </div>
          )}
          {lazyLoading || isLoadingProducts ? (
            <div className="detail__inner">
              <div className="detail__head">
                <div className="detail__info">
                  <h4 className="placeholder">{}</h4>
                  <div className="detail__info-price placeholder"></div>
                </div>
              </div>
              <div className="detail__body">
                {[1, 2, 3].map((item, index) => {
                  return (
                    <div className="tabItem placeholder" key={index}></div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="detail__inner">
              <div className="detail__head">
                <div className="detail__info">
                  <h4>{currentProduct?.title}</h4>
                  <div className="detail__info-price">
                    {currentProduct?.off ? (
                      <>
                        {(
                          (currentProduct?.price -
                            (currentProduct?.off / 100) *
                              currentProduct?.price) *
                          (quantity ? quantity : 1)
                        ).toFixed(2)}
                        $<span>{currentProduct?.price.toFixed(2)}$</span>
                      </>
                    ) : (
                      <>
                        {(
                          currentProduct?.price * (quantity ? quantity : 1)
                        ).toFixed(2)}
                        $
                      </>
                    )}
                  </div>
                </div>
                <button
                  type="button"
                  className={'detail__btn ' + (isSaved ? 'active' : '')}
                  onClick={savedHandler}
                  ref={saveBtn}
                >
                  {isSaved ? markIcon : markStroke}
                </button>
              </div>
              <div className="detail__body" style={{ position: 'relative' }}>
                {cartLoadingStatus === REDUX_ASYNC_FETCH_STATUS.falied && (
                  <div
                    style={{
                      position: 'absolute',
                      right: 0,
                      top: 0,
                      transform: 'translateY(-100%)',
                      color: '#ee0303',
                    }}
                  >
                    {`Error: ${cartLoadingError}`}
                  </div>
                )}
                <div className="spinbox__outer">
                  {quantity > 0 ? (
                    <div className="spinbox">
                      {quantity === 1 ? (
                        <button
                          disabled={
                            cartLoadingStatus ===
                            REDUX_ASYNC_FETCH_STATUS.loading
                          }
                          className="spinbox__trash"
                          type="button"
                          onClick={removeFromCartHandler}
                        >
                          {trashIcon}
                        </button>
                      ) : (
                        <button
                          className="spinbox__crement"
                          onClick={removeFromCartHandler}
                          disabled={
                            cartLoadingStatus ===
                            REDUX_ASYNC_FETCH_STATUS.loading
                          }
                        >
                          -
                        </button>
                      )}
                      <div className="spinbox__numb">
                        {cartLoadingStatus ===
                        REDUX_ASYNC_FETCH_STATUS.loading ? (
                          <ClipLoader
                            color="#fff"
                            speedMultiplier={0.5}
                            size={25}
                          />
                        ) : (
                          quantity
                        )}
                      </div>
                      <button
                        disabled={
                          cartLoadingStatus === REDUX_ASYNC_FETCH_STATUS.loading
                        }
                        className="spinbox__crement"
                        onClick={addToCartHandler}
                      >
                        +
                      </button>
                    </div>
                  ) : cartLoadingStatus === REDUX_ASYNC_FETCH_STATUS.loading ? (
                    <div style={{ textAlign: 'center' }}>
                      <ClipLoader
                        color="#fff"
                        speedMultiplier={0.5}
                        size={25}
                      />
                    </div>
                  ) : (
                    <div
                      ref={addBtn}
                      className="spinbox__btn"
                      onClick={addToCartHandler}
                    >
                      Add to {cartStroke}
                    </div>
                  )}
                </div>

                <Tabs product={currentProduct} />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
