import { configureStore } from '@reduxjs/toolkit';
import categoryReducer from './slices/categorySlice';
import productsReducer from './slices/productsSlice';
import cartReducer from './slices/cartSlice';
import savedReducer from './slices/savedSlice';
import userReducer from './slices/customersSlice';
import checkoutReducer from './slices/checkoutSlice';
import storesReducer from './slices/storesSlice';
export const store = configureStore({
  reducer: {
    categories: categoryReducer,
    products: productsReducer,
    cart: cartReducer,
    saved: savedReducer,
    customers: userReducer,
    checkout: checkoutReducer,
    stores: storesReducer,
  },
});
