import apiClient from '../lib/apiClient';
import apiRoutes from '../lib/routes/api';
import {
  DEFAULT_PAYMENT_METHOD_LIMIT,
  DEFAULT_PAYMENT_METHOD_OFFSET,
  DEFAULT_LOCALE,
} from '../constants';

export async function getCustomers(
  offset = DEFAULT_PAYMENT_METHOD_OFFSET,
  limit = DEFAULT_PAYMENT_METHOD_LIMIT,
) {
  const url = apiRoutes.apiCustomersPath();
  const params = new URLSearchParams({
    offset,
    limit,
  });
  const result = await apiClient.get(url, params);
  return result ? result : [];
}

export async function createCustomer({
  fullName,
  telegramId,
  username,
  email,
  phone,
  locale,
}) {
  // https://api.cxnnect.app/customers/create?telegram_id=123&full_name=Roman&username=roman_poludnev&locale=en_US
  const url = apiRoutes.apiCreateCustomerPath();
  const params = new URLSearchParams({
    full_name: fullName,
  });
  if (!!telegramId) params.append('telegram_id', telegramId);
  if (!!username) params.append('username', username);
  if (!!email) params.append('email', email);
  if (!!phone) params.append('phone', phone);
  params.append('locale', locale ? locale : DEFAULT_LOCALE);
  const result = await apiClient.post(url, params);
  return result;
}

export async function getBillingInfo(
  customerId,
  offset = DEFAULT_PAYMENT_METHOD_OFFSET,
  limit = DEFAULT_PAYMENT_METHOD_LIMIT,
) {
  const url = apiRoutes.apiGetBillingInfo(customerId);
  const params = new URLSearchParams({
    offset,
    limit,
  });
  const result = await apiClient.get(url, params);
  return !!result ? result : [];
}

export async function getShippingInfo(
  customerId,
  offset = DEFAULT_PAYMENT_METHOD_OFFSET,
  limit = DEFAULT_PAYMENT_METHOD_LIMIT,
) {
  const url = apiRoutes.apiGetShippingInfo(customerId);
  const params = new URLSearchParams({
    offset,
    limit,
  });
  const result = await apiClient.get(url, params);
  return !!result ? result : [];
}

export async function createBillingInfo({
  customerId,
  country,
  city,
  addressLine1,
  addressLine2,
  postalCode,
  state,
}) {
  const url = apiRoutes.apiCustomersCreateBillingInfoPath(customerId);
  const params = new URLSearchParams({
    country,
    city,
    line1: addressLine1,
    postal_code: postalCode,
    state,
  });
  if (!!addressLine2 && addressLine2.length > 0)
    params.append('line2', addressLine2);
  const result = await apiClient.post(url, params);
  return result;
}

export async function createShipingInfo({
  customerId,
  country,
  city,
  addressLine1,
  addressLine2,
  postalCode,
  state,
}) {
  const url = apiRoutes.apiCustomersCreateShippingInfoPath(customerId);
  const params = new URLSearchParams({
    country,
    city,
    line1: addressLine1,
    line2: !!addressLine2 ? addressLine2 : ' ',
    postal_code: postalCode,
    state,
  });
  // if (!!addressLine2) params.append('line2', addressLine2);
  const result = await apiClient.post(url, params);
  return result;
}
