import {
  DEFAULT_PAYMENT_METHOD_LIMIT,
  DEFAULT_PAYMENT_METHOD_OFFSET,
} from '../constants';

import apiClient from '../lib/apiClient';
import apiRoutes from '../lib/routes/api';

export const getInvoiceById = async ({ invoiceId, server }) => {
  const url = apiRoutes.apiInvoiceById(invoiceId);
  const params = new URLSearchParams({ server });
  const result = await apiClient.get(url, params);
  return result;
};

export const getInvoiceItemsByInvoiceId = async (
  { invoiceId, server },
  offset = DEFAULT_PAYMENT_METHOD_OFFSET,
  limit = DEFAULT_PAYMENT_METHOD_LIMIT,
) => {
  const url = apiRoutes.apiInvoicesItemsByInvoiceId(invoiceId);
  const params = new URLSearchParams({ server, offset, limit });
  const result = await apiClient.get(url, params);
  return result;
};

export async function createInvoiceAPI({
  customerId,
  server,
  subtotal,
  finalTotal,
  currency,
  storeId,
  paymentMethodId,
  shippingInfoId,
  billingInfoId,
  promocodeId,
}) {
  const url = apiRoutes.apiCustomersCreateInvoicePath(customerId);
  const params = new URLSearchParams({
    server,
    subtotal,
    final_total: finalTotal,
    currency,
    store_id: storeId,
    payment_method_id: paymentMethodId,
    shipping_info_id: shippingInfoId,
    billing_info_id: billingInfoId,
  });

  if (!!promocodeId) params.append('promocode_id', promocodeId);

  const result = apiClient.post(url, params);
  return result;
}

export async function addCartItemToInvoice({
  invoiceId,
  server,
  productId,
  productVariationId,
}) {
  const url = apiRoutes.apiAddItemToInvoiceItemPath(invoiceId);
  const params = new URLSearchParams({ server });
  params.append('product_id', productId);

  if (!!productVariationId) {
    params.append('product_variation_id', productVariationId);
  }

  const result = await apiClient.post(url, params);
  return result;
}

export async function getPaymentMethodsAPI(
  storeId,
  offset = DEFAULT_PAYMENT_METHOD_OFFSET,
  limit = DEFAULT_PAYMENT_METHOD_LIMIT,
) {
  const url = `${apiRoutes.apiPaymentMethodsPath()}`;
  const params = new URLSearchParams({
    store_id: storeId,
    offset,
    limit,
  });
  const result = await apiClient.get(url, params);

  return !!result ? result : [];
}
