import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Header from '../../components/Shared/Header';
import Filter from '../../components/Shared/Filter';
import ProductItem from '../../components/Product/ProductItem';

import {
  selectAllProducts,
  getFetcingPruductsStatus,
} from '../../redux/slices/productsSlice';

export default function Products() {
  const allProducts = useSelector(selectAllProducts);
  const fetchingProductsStatus = useSelector(getFetcingPruductsStatus);

  const [isLoadingProducts, setIsLoadingProducts] = useState(true);

  const defaultFilters = {
    sortBy: {
      id: '1',
      value: 'Suggested products',
      default: true,
    },
    color: 'all',
    price: { id: '1', value: 'All prices', default: true },
    onSale: false,
    inStock: false,
  };
  const [filters, setFilters] = useState(defaultFilters);
  const updateFilters = (data) => {
    setFilters((filters) => ({ ...filters, ...data }));
  };

  useEffect(() => {
    if (
      fetchingProductsStatus === 'idle' ||
      fetchingProductsStatus === 'loading'
    ) {
      setIsLoadingProducts(true);
    } else {
      setIsLoadingProducts(false);
    }
  }, [fetchingProductsStatus]);

  return (
    <>
      <Header backHome={true} title={<h4>All products</h4>} />
      <div className="product">
        <div className="auto__container">
          <div className="filterSms">
            <Filter
              filters={filters}
              setFilters={setFilters}
              defaultFilters={defaultFilters}
            />
            <div className="opt">
              <input
                type="checkbox"
                checked={filters.onSale}
                onChange={(e) =>
                  updateFilters({
                    onSale: e.target.checked,
                  })
                }
              />
              <label htmlFor="">On sale</label>
            </div>
            <div className="opt">
              <input
                type="checkbox"
                checked={filters.inStock}
                onChange={(e) =>
                  updateFilters({
                    inStock: e.target.checked,
                  })
                }
              />
              <label htmlFor="">In stock</label>
            </div>
            {filters.onSale || filters.inStock || !filters.sortBy?.default ? (
              <button
                className="clear"
                onClick={() => updateFilters({ ...defaultFilters })}
              >
                Clear All
              </button>
            ) : (
              ''
            )}
          </div>
          <div className="product__inner">
            {isLoadingProducts ? (
              <div className="product__row">
                {[1, 2, 3, 4, 5, 6, 7, 8].map((item, index) => {
                  return (
                    <div className="productItem" key={index}>
                      <div className="productItem__image placeholder"></div>
                      <div className="productItem__content">
                        <h6 className="placeholder">{}</h6>
                        <div className="productItem__content-bot">
                          <div className="productItem__price placeholder"></div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="product__row">
                {allProducts.map((item) => {
                  return <ProductItem key={item.id} {...item} />;
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
