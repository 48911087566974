import { useId } from 'react';
import styles from './InputGroup.module.css';

export const InputGroup = ({ labelValue, required, disabled, name }) => {
  const inputId = useId();
  return (
    <div className={styles.inputGroup}>
      <input
        id={`${inputId}-${labelValue}`}
        name={name}
        className={styles.input}
        required={required}
        placeholder={`${labelValue}`}
        disabled={disabled}
      ></input>
      <label htmlFor={`${inputId}-${labelValue}`} className={styles.label}>
        {labelValue}
      </label>
    </div>
  );
};
