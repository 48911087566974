import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import {
  DEFAULT_CUSTOMER_ID,
  REDUX_ASYNC_FETCH_STATUS,
  ER_TG_USER_ERROR,
  ER_USER_ERROR,
} from '../../constants';
import {
  getCustomers,
  createCustomer,
  getBillingInfo,
  getShippingInfo,
} from '../../repositories/CustomerRepository';

const initialState = {
  status: REDUX_ASYNC_FETCH_STATUS.idle,
  currentCustomerId: null,
  currentCustomer: null,
  billingInfo: null,
  billingInfoStatus: REDUX_ASYNC_FETCH_STATUS.idle,
  selectedBillingId: null,
  shippingInfo: null,
  selectedShippingId: null,
  shippingInfoStatus: REDUX_ASYNC_FETCH_STATUS.idle,
  error: null,
};

export const fetchCustomers = createAsyncThunk(
  'customers/fetchCustomers',
  async () => {
    const result = await getCustomers();
    return result;
  },
);

export const setCurrentCustomer = createAsyncThunk(
  'customers/setCurrentCustomer',
  async (telegramUser) => {
    const customers = await getCustomers();
    console.log('customers', customers, telegramUser);
    if (!telegramUser) {
      
      const customer = customers.find(
        (customer) => customer.telegram_id === 1234,
      );
      console.log('customer', customer);
      if (!customer) throw new Error(ER_USER_ERROR);
      return customer;
      

      throw new Error(ER_TG_USER_ERROR);
    }
    const customerByTgId = customers.find(
      (customer) => customer.telegram_id === telegramUser.id,
    );
    console.log('customerByTgId', customerByTgId);
    if (!customerByTgId) {
      const fullName = `${telegramUser['first_name']} ${telegramUser['last_name']}`;
      const newCustomer = await createCustomer({
        fullName,
        telegramId: telegramUser.id,
        username: telegramUser.username,
      });
      return newCustomer;
    }

    //TODO: add handling known user: ask customer if wants to create account
    // if (!customerByTgId)
    //   throw new Error('unknown user, user telegram id: ', currentTgId);
    return customerByTgId;
  },
);

export const fetchBillingInfo = createAsyncThunk(
  'customers/fetchBillingInfo',
  async (customerId) => {
    const billingInfo = await getBillingInfo(customerId);
    return billingInfo;
  },
);
export const fetchShippingInfo = createAsyncThunk(
  'customers/fetchShippingInfo',
  async (customerId) => {
    const shippingInfo = await getShippingInfo(customerId);
    return shippingInfo;
  },
);

export const userSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    setSelectedShippingId: (state, { payload }) => {
      state.selectedShippingId = payload;
    },
    setSelectedBillingId: (state, { payload }) => {
      state.selectedBillingId = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomers.pending, (state) => {
        state.status = REDUX_ASYNC_FETCH_STATUS.loading;
      })
      .addCase(fetchCustomers.rejected, (state) => {
        state.status = REDUX_ASYNC_FETCH_STATUS.falied;
      })
      .addCase(fetchCustomers.fulfilled, (state, { payload }) => {
        state.status = REDUX_ASYNC_FETCH_STATUS.succeeded;
        if (!!payload && payload.length > 0) {
          state.currentCustomerId = Number(payload[1].id);
        } else {
          state.currentCustomerId = Number(DEFAULT_CUSTOMER_ID);
        }
      })
      .addCase(setCurrentCustomer.pending, (state) => {
        state.status = REDUX_ASYNC_FETCH_STATUS.loading;
        state.error = null;
      })
      .addCase(setCurrentCustomer.rejected, (state, { error }) => {
        state.status = REDUX_ASYNC_FETCH_STATUS.falied;
        if (error.message === ER_TG_USER_ERROR) {
          state.error = 'Can not get a telegram user.';
        }
        if (error.message === ER_USER_ERROR) {
          state.error = 'Can not get a user.';
        } else {
          state.error = 'Unknown customer error';
        }
      })
      .addCase(setCurrentCustomer.fulfilled, (state, { payload }) => {
        state.status = REDUX_ASYNC_FETCH_STATUS.succeeded;
        state.currentCustomerId = payload.id;
        state.currentCustomer = payload;
      })
      .addCase(fetchBillingInfo.pending, (state) => {
        state.billingInfoStatus = REDUX_ASYNC_FETCH_STATUS.loading;
      })
      .addCase(fetchBillingInfo.fulfilled, (state, { payload }) => {
        state.billingInfoStatus = REDUX_ASYNC_FETCH_STATUS.succeeded;
        state.billingInfo = payload;
        if (!state.selectedBillingId)
          state.selectedBillingId =
            (payload.length > 0 && payload[0]?.id) || null;
      })
      .addCase(fetchBillingInfo.rejected, (state, { error }) => {
        console.error('fetch billing info error', error);
        state.billingInfoStatus = REDUX_ASYNC_FETCH_STATUS.falied;
      })
      .addCase(fetchShippingInfo.pending, (state) => {
        state.shippingInfoStatus = REDUX_ASYNC_FETCH_STATUS.loading;
      })
      .addCase(fetchShippingInfo.fulfilled, (state, { payload }) => {
        state.shippingInfoStatus = REDUX_ASYNC_FETCH_STATUS.succeeded;
        state.shippingInfo = payload;

        if (!state.selectedShippingId)
          state.selectedShippingId =
            (payload.length > 0 && payload[0]?.id) || null;
      })
      .addCase(fetchShippingInfo.rejected, (state, { error }) => {
        console.error('fetch shipping info error', error);
        state.shippingInfoStatus = REDUX_ASYNC_FETCH_STATUS.falied;
      });
  },
});

export const getCurrentUserId = (state) => state.customers.currentCustomerId;
export const customers = (state) => state.customers;
export const selectCustomers = (state) => state.customers;
export const selectBillingInfo = createSelector(
  [selectCustomers],
  ({ billingInfo, billingInfoStatus, selectedBillingId }) => {
    const selectedBillingInfo = billingInfo?.find(
      (shipping) => shipping.id === selectedBillingId,
    );
    return {
      billingInfo,
      billingInfoStatus,
      selectedBillingId,
      selectedBillingInfo,
    };
  },
);
export const selectShippingInfo = createSelector(
  [selectCustomers],
  ({ shippingInfo, shippingInfoStatus, selectedShippingId }) => {
    const selectedShippingInfo = shippingInfo?.find(
      (shipping) => shipping.id === selectedShippingId,
    );
    return {
      shippingInfo,
      shippingInfoStatus,
      selectedShippingId,
      selectedShippingInfo,
    };
  },
);

export const { setSelectedShippingId, setSelectedBillingId } =
  userSlice.actions;

export default userSlice.reducer;
