import React from 'react';
import { useDispatch } from 'react-redux';
import { TLoginButton, TLoginButtonSize } from 'react-telegram-auth';
import { setCurrentCustomer } from '../../redux/slices/customersSlice';
export default function Auth() {
  const dispatch = useDispatch();
  return (
    <>
      <TLoginButton
        botName="CXNNECT_test_bot"
        buttonSize={TLoginButtonSize.Large}
        lang="en"
        usePic={false}
        cornerRadius={20}
        onAuthCallback={(user) => {
          alert('Hello, user!', user);
          // navigate("/home");
        }}
        requestAccess={'write'}
        additionalClasses={'css-class-for-wrapper'}
      />
      <TLoginButton
        botName="poludnev_bot"
        buttonSize={TLoginButtonSize.Large}
        lang="en"
        usePic={false}
        cornerRadius={20}
        onAuthCallback={(user) => {
          console.log('user', user, typeof user);
          dispatch(setCurrentCustomer(user));

          // auth_date: 1692780701;
          // first_name: 'Roman';
          // hash: 'cb34591a315b48908198f3109c5b0e5532bb3f33552c544a840323cc75401523';
          // id: 172610082;
          // last_name: 'Poludnev';
          // photo_url: 'https://t.me/i/userpic/320/iXGtaiWpnFO5_fWKCQxDvKn4jh-ubK8kR_U8VW5pBIs.jpg';
          // username: 'roman_poludnev';
        }}
        requestAccess={'write'}
        additionalClasses={'css-class-for-wrapper'}
      />
    </>
  );
}
