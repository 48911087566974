import React, { useEffect, useState } from 'react';
import { arrowLeft, searchIcon } from './SVG';
import { Link, useLocation } from 'react-router-dom';

export default function Search({ categories }) {
  const [isActive, setIsActive] = useState(true);
  const [addClass, setAddClass] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const reveal = () => {
      setTimeout(() => {
        setAddClass(isActive);
      }, 50);
    };
    reveal();
  }, [isActive]);

  useEffect(() => {
    if (isActive) {
      document.body.classList.add('active');
    } else {
      document.body.classList.remove('active');
    }
  }, [isActive]);
  useEffect(() => {
    setIsActive(false);
    document.body.classList.remove('active');
  }, [location]);
  return (
    <div className="search">
      <div className="searchBtn" onClick={() => setIsActive(true)}>
        Search
      </div>
      {isActive && (
        <div className={'searchContent ' + (addClass ? 'active' : '')}>
          <div className="searchContent__inner">
            <div className="searchContent__header">
              <div className="back" onClick={() => setIsActive(false)}>
                {arrowLeft}
              </div>
              <div className="searchInput">
                <input type="text" placeholder="Search" />
                <button type="button">{searchIcon}</button>
              </div>
            </div>
            <div className="searchContent__body">
              {categories.map((item, index) => {
                return <SearchItem {...item} key={index} />;
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
const SearchItem = (props) => {
  return (
    <Link to={`category/${props.id}`} className="searchItem">
      <div className="searchItem__icon">{searchIcon}</div>
      <div className="searchItem__text">
        <h6>{props.title}</h6>
      </div>
    </Link>
  );
};
