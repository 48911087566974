import React, { useEffect, useState } from "react";
import { chevronBottom } from "./SVG";
import { Link, useLocation } from "react-router-dom";

export default function Browse({ categories }) {
  const [tab, setTab] = useState(false);
  const [addClass, setAddClass] = useState(false);

  const closeBrowser = (e) => {
    if (e.target === e.currentTarget) setTab(false);
  };
  const location = useLocation();
  useEffect(() => {
    if (tab) {
      document.body.classList.add("active");
    } else {
      document.body.classList.remove("active");
    }
  }, [tab]);
  useEffect(() => {
    setTab(false);
    document.body.classList.remove("active");
  }, [location]);
  
  useEffect(() => {
    const reveal = () => {
      setTimeout(() => {
        setAddClass(tab);
      }, 50);
    };
    reveal();
  }, [tab]);

  return (
    <div className="filter">
      <div className="filterBtn" onClick={() => setTab(!tab)}>
        Browse {chevronBottom}
      </div>
      {tab && (
        <div
          className={"filterContent " + (addClass ? "active" : "")}
          onClick={closeBrowser}
        >
          <div className="filterContent__inner">
            <div className="filterContent__header">
              <h5>Browse</h5>
            </div>
            <div className="filterContent__body">
              <Link to="/products" className="filterContent__row">
                <h5>All products</h5>
              </Link>
              {categories.map((item, index) => {
                return (
                  <Link
                    to={`category/${item.id}`}
                    className="filterContent__row reg"
                    key={index}
                  >
                    <h5>{item.title}</h5>
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
