import React, { useState } from "react";
import Description from "./Description";
import Details from "./Details";
import Shipping from "./Shipping";

export default function Tabs() {
  const [tab, setTab] = useState(null);

  return (
    <>
      <Description
        tab={tab}
        setTab={() => setTab(tab === "description" ? null : "description")}
      />
      <Details
        tab={tab}
        setTab={() => setTab(tab === "details" ? null : "details")}
      />
      <Shipping
        tab={tab}
        setTab={() => setTab(tab === "shipping" ? null : "shipping")}
      />
    </>
  );
}
