import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ProductItem from '../components/Product/ProductItem';
import Header from '../components/Shared/Header';

import {
  selectCategoryById,
  getFetchingCategoriesStatus,
} from '../redux/slices/categorySlice';
import {
  getFetcingPruductsStatus,
  selectProductsByCategoryId,
} from '../redux/slices/productsSlice';

export default function Category() {
  const { id } = useParams();
  const currentCategory = useSelector(selectCategoryById(id));

  const fetchingCategoriesStatus = useSelector(getFetchingCategoriesStatus);
  const fetchingProductsStatus = useSelector(getFetcingPruductsStatus);
  const filteredProducst = useSelector((state) =>
    selectProductsByCategoryId(state, id),
  );

  const [isLoadingCategory, setIsLoadingCategory] = useState(true);
  const [isLoadingProducts, setIsLoadingProducts] = useState(true);

  useEffect(() => {
    if (
      fetchingCategoriesStatus === 'idle' ||
      fetchingCategoriesStatus === 'loading'
    ) {
      setIsLoadingCategory(true);
    } else {
      setIsLoadingCategory(false);
    }
    if (
      fetchingProductsStatus === 'idle' ||
      fetchingProductsStatus === 'loading'
    ) {
      setIsLoadingProducts(true);
    } else {
      setIsLoadingProducts(false);
    }
  }, [fetchingCategoriesStatus, fetchingProductsStatus]);

  const [lazyLoading, setLazyLoaind] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setLazyLoaind(false);
    }, 2000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      <Header backHome={true} />
      <div className="category">
        <div className="auto__container">
          <div className="category__inner">
            {lazyLoading || isLoadingCategory ? (
              <div className="productCat">
                <div className="productCat__image placeholder"></div>
                <div className="productCat__content">
                  <h6 className="placeholder">{}</h6>
                  <h2 className="placeholder">{}</h2>
                </div>
              </div>
            ) : (
              <div className="productCat">
                <div className="productCat__image">
                  <img src={currentCategory?.image} alt="category" />
                </div>
                <div className="productCat__content">
                  <h6>
                    {filteredProducst.length}
                    {filteredProducst.length > 1 ? ' products' : ' product'}
                  </h6>
                  <h2>{currentCategory?.title}</h2>
                </div>
              </div>
            )}
            {lazyLoading || isLoadingProducts ? (
              <div className="product__row">
                {[1, 2, 3, 4].map((item, index) => {
                  return (
                    <div className="productItem" key={index}>
                      <div className="productItem__image placeholder"></div>
                      <div className="productItem__content">
                        <h6 className="placeholder">{}</h6>
                        <div className="productItem__content-bot">
                          <div className="productItem__price placeholder"></div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="product__row">
                {filteredProducst.map((item) => {
                  return <ProductItem key={item.id} {...item} />;
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
