export * from './errorTypes';
export const DEFAULT_STORE_ID = '6';
export const DEFAULT_CUSTOMER_ID = '3';
export const DEFAULT_PAYMENT_METHOD_OFFSET = 0;
export const DEFAULT_PAYMENT_METHOD_LIMIT = 100;
export const DEFAULT_SERVER_NAME = 'europe';
export const DEFAULT_GATEWAY_HOST = 'https://api.cxnnect.app';
export const DEFAULT_LOCALE = 'en_US';

export const REDUX_ASYNC_FETCH_STATUS = {
  idle: 'IDLE',
  loading: 'LOADING',
  succeeded: 'SUCCEEDED',
  falied: 'FAILED',
};
