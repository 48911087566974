import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DEFAULT_SERVER_NAME, REDUX_ASYNC_FETCH_STATUS } from '../../constants';
import { getStores } from '../../repositories/StoresRepository';

const initialState = {
  status: REDUX_ASYNC_FETCH_STATUS.idle,
  stores: [],
  currentStoreId: null,
  server: DEFAULT_SERVER_NAME,
};

export const fetchStores = createAsyncThunk(
  'stores/fetchStores',
  async (server) => {
    const stores = await getStores(server);
    return stores;
  },
);

export const storesSlice = createSlice({
  name: 'stores',
  initialState,
  reducers: {
    setCurrentStore: (state, action) => {
      state.currentStoreId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStores.pending, (state) => {
        state.status = REDUX_ASYNC_FETCH_STATUS.loading;
      })
      .addCase(fetchStores.rejected, (state) => {
        state.status = REDUX_ASYNC_FETCH_STATUS.falied;
      })
      .addCase(fetchStores.fulfilled, (state, { payload }) => {
        state.status = REDUX_ASYNC_FETCH_STATUS.succeeded;
        state.stores = payload;
      });
  },
});

export const selectStores = (state) => state.stores.stores;
export const getCurrentStoreId = (state) => state.stores.currentStoreId;

export const { setCurrentStore } = storesSlice.actions;

export default storesSlice.reducer;
