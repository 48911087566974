import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  arrowLeft,
  barsIcon,
  cartStroke,
  markStroke,
  moonIcon,
  sunIcon,
} from './SVG';
import { selectCartTotalQuantity } from '../../redux/slices/cartSlice';
import Auth from '../Auth/Auth';
import styles from './Header.module.css';
export default function Header({ backHome, title }) {
  const cartTotalQuantity = useSelector(selectCartTotalQuantity);
  const { currentCustomer, error } = useSelector((state) => state.customers);

  const [menu, setMenu] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const close = (e) => {
    if (e.target === e.currentTarget) setMenu(false);
  };
  useEffect(() => {
    if (menu) {
      document.body.classList.add('active');
    } else {
      document.body.classList.remove('active');
    }
  }, [menu]);
  useEffect(() => {
    setMenu(false);
    document.body.classList.remove('active');
  }, [location]);
  const [checked, setChecked] = useState(true);
  const switchCheck = useRef(null);
  // theme function start
  useEffect(() => {
    if (switchCheck.current) {
      const storageThemeState = localStorage.getItem('theme');
      document.documentElement.setAttribute('data-theme', storageThemeState);

      setChecked(!storageThemeState || storageThemeState === 'dark');
      console.log(storageThemeState, storageThemeState === 'dark');
    }
  }, [switchCheck]);
  const switchTheme = (e) => {
    if (e.target.checked) {
      document.documentElement.setAttribute('data-theme', 'dark');
      localStorage.setItem('theme', 'dark');
    } else {
      document.documentElement.setAttribute('data-theme', 'light');
      localStorage.setItem('theme', 'light');
    }
    setChecked(e.target.checked);
  };
  // theme function end
  return (
    <>
      <div className="header">
        <div className="auto__container">
          <div className="header__inner">
            <div className="header__inner-row">
              {backHome ? (
                <button
                  type="button"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    navigate(-1);
                  }}
                  className="button back"
                >
                  {arrowLeft}
                </button>
              ) : (
                ''
              )}
              <>{title}</>
            </div>
            <div className="header__inner-row">
              {cartTotalQuantity >= 1 ? (
                <Link className="headerCart" to="/cart">
                  {cartStroke}

                  <span>{cartTotalQuantity}</span>
                </Link>
              ) : (
                ''
              )}
              <div className="burger" onClick={() => setMenu(!menu)}>
                {barsIcon}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={'menu ' + (menu ? 'active' : '')} onClick={close}>
        <div className="menu__inner">
          <div className="menu__header">
            <div className="menu__header-avatar">
              <img
                src={process.env.PUBLIC_URL + '/images/apple.jpg'}
                alt="product"
              />
            </div>
            <div className="menu__header-content">
              <h5>Apple Store</h5>
              <h6>2.24m users</h6>
            </div>
          </div>
          <div className="menu__body">
            <h5>Your Account</h5>
            {currentCustomer ? (
              <div className={styles.Account}>
                <p className={styles.AccountP}>
                  Customer Id: {currentCustomer.id}
                </p>
                <p className={styles.AccountP}>
                  Customer Name: {currentCustomer.full_name}
                </p>
                <p className={styles.AccountP}>
                  Customer username: {currentCustomer.username}
                </p>
                <p className={styles.AccountP}>
                  Telegram Id: {currentCustomer.telegram_id}
                </p>
              </div>
            ) : (
              `${error ? error + ' Can not log in.' : 'Can not log in.'}`
            )}

            <div className="switch__outer">
              <h6>Appearence</h6>
              <div className="switch">
                <input
                  type="checkbox"
                  ref={switchCheck}
                  checked={checked}
                  onChange={switchTheme}
                />
                <label htmlFor="">
                  <span> {checked ? <>{sunIcon}</> : <>{moonIcon}</>}</span>
                </label>
              </div>
            </div>
            <Link to="/saved" className="menu__body-link">
              {markStroke}
              Saved products
            </Link>
            <Link to="/cart" className="menu__body-link">
              {cartStroke}
              Cart
            </Link>
            <button type="button" className="button web">
              View Website
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
